<template>
    <div>
        <el-upload class="upload-demo" name="file" :headers="headers" :action="uploadImagePath" accept=".jpg,.png"
            :before-upload="beforeAvatarUpload" :on-success="handleSuccess" multiple />
        <quill-editor ref="toref" v-model="content" :options="editorOption" style="height: 340px;" :aria-readonly="true"
            @blur="onEditorBlur($event)" @focus="onEditorFocus($event)" @ready="init" />

        <!-- <quill-editor @ready="init">
        </quill-editor> -->
    </div>
</template>

<script>
import Vue from 'vue'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import router from '../router';

// import Quill, { Parchment } from 'quill'
import Quill from 'quill'

import { ImageDrop } from 'quill-image-drop-module'  // 图片拖动插件
import ImageResize from 'quill-image-resize-module'  // 图片设置大小插件

import { getUploadImagePath } from '../utils/util'

//Quill.register('modules/imageDrop', ImageDrop)       // 图片拖动插件
//Quill.register('modules/imageResize', ImageResize)   // 图片设置大小插件
// 鼠标移入显示title文字
import { addQuillTitle } from '@/assets/quill-title.js'
Vue.use(quillEditor)
// 图片上传接口
// import { richTextImgUpload, richTextVideoUpload } from '@/api/uploadFIle'
// let Parchment = Quill.import('parchment')
// class lineHeightAttributor extends Parchment.Attributor.Style { }
// const lineHeightStyle = new lineHeightAttributor("lineheight", "ql-lineheight", {
//     scope: Parchment.Scope.INLINE,
//     whitelist: ["", "1", "1-5", "1-75", "2", "3", "4", "5"]
// });
// console.log('lineHeightStyle===>', lineHeightStyle)

// // class lineHeightAttributor extends Parchment.ClassAttributor { }
// // const lineHeightStyle = new lineHeightAttributor("lineheight", "ql-lineheight", {
// //     scope: Parchment.Scope.INLINE,
// //     whitelist: ["", "1", "1-5", "1-75", "2", "3", "4", "5"]
// // });



// Quill.register({ 'formats/lineHeight': lineHeightStyle }, true);

// 工具栏配置
const toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'], // 加粗，斜体，下划线，删除线
    ['blockquote', 'code-block'], // 引用，代码块

    [{ header: 1 }, { header: 2 }], // 标题，键值对的形式；1、2表示字体大小
    [{ list: 'ordered' }, { list: 'bullet' }], // 列表
    [{ script: 'sub' }, { script: 'super' }], // 上下标
    [{ indent: '-1' }, { indent: '+1' }], // 缩进
    [{ direction: 'rtl' }], // 文本方向

    [{ size: ['small', false, 'large', 'huge'] }], // 字体大小
    [{ header: [1, 2, 3, 4, 5, 6, false] }], // 几级标题

    [{ color: [] }, { background: [] }], // 字体颜色，字体背景颜色
    [{ font: [] }], // 字体
    [{ align: [] }], // 对齐方式
    // [{ lineheight: ['initial', '1', '1.5', '1.75', '2', '3', '4', '5'] }], // 对齐方式
    [{ lineheight: ["default", "1", "1.5", "1.75", "2", "3", "4"] }], // 对齐方式

    // [{ lineHeight: lineHeight }],
    ['clean'], // 清除字体样式
    ['image']//, 'video'] // 上传图片、上传视频
]



/* 富文本编辑图片上传配置*/
const uploadConfig = {
    action: '', // 必填参数 图片上传地址
    methods: 'POST', // 必填参数 图片上传方式
    token: '', // 可选参数 如果需要token验证，假设你的token有存放在sessionStorage
    name: 'img', // 必填参数 文件的参数名
    size: 500, // 可选参数   图片大小，单位为Kb, 1M = 1024Kb
    accept: 'image/png, image/gif, image/jpeg, image/bmp, image/x-icon' // 可选 可上传的图片格式
}
export default {
    components: {
        quillEditor
    },
    computed: {
        headers() {
            return {
                // 'Content-Type': 'multipart/form-data',
                'Authorization': localStorage.getItem('refreshToken')
            }
        }
    },
    props: {
        contents: {
            type: String,
            default: ''
        },
        default: '',
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            content: '',
            videoOrimg: true,
            uploadImagePath: '',
            editorOption: {
                placeholder: '请输入...',
                modules: {
                    //ImageResize: {}, //图片拖动设置大小
                    //ImageDrop: true, // 图片拖动
                    toolbar: {
                        container: toolbarOptions, //工具栏
                        handlers: {
                            image: (value) => {
                                if (value) {
                                    // 这是是为了获取你当前点击的那个富文本框
                                    this.videoOrimg = true
                                    document.querySelectorAll('.upload-demo input')[0].click()
                                } else {
                                    this.quill.format('image', false)
                                }
                            },
                            video: (value) => {
                                if (value) {
                                    // 这是是为了获取你当前点击的那个富文本框
                                    this.videoOrimg = false
                                    document.querySelectorAll('.upload-demo input')[0].click()
                                } else {
                                    this.quill.format('image', false)
                                }
                            },
                            lineheight: function (value) {
                                if (value) {
                                    this.quill.format('lineHeight', value);
                                } else {
                                    console.log(value);
                                }
                            },

                        }
                    }
                },
                theme: 'snow'
            }
        }
    },
    mounted() {
        addQuillTitle()
        this.uploadImagePath = getUploadImagePath()
        // this.init()
        // Quill.register({ 'formats/lineHeight': lineHeightStyle }, true);
    },

    methods: {
        // 覆盖默认的上传行为
        requestUpload(params) {
            const formData = new FormData()
            formData.append('img', params.file)
            // 图片
            if (this.videoOrimg) {
                // richTextImgUpload(formData).then((res) => {
                //     params.onSuccess(res)
                // })
            } else {
                // richTextVideoUpload(formData).then((res) => {
                //     params.onSuccess(res)
                // })
            }

        },
        // 判断图片后缀
        beforeAvatarUpload(file) {
            const isJPG = file.type === 'image/jpeg' || 'image/png'
            const isVideo = file.type === 'video/mp4'
            const isLt2M = file.size / 1024 / 1024 < 10
            // 图片
            if (this.videoOrimg) {
                if (!isJPG) {
                    this.$message.error('上传图片只能是 JPG , PNG格式!')
                }
                if (!isLt2M) {
                    this.$message.error('上传图片大小不能超过 10MB!')
                }
                return isJPG && isLt2M
            } else {
                if (!isVideo) {
                    this.$message.error('上传视频只能是 MP4格式!')
                }
                return isVideo
            }
        },
        // 上传成功
        handleSuccess(res, file) {
            if (res.code == 200) {
                // 动态添加 ref  通过 eval () 去执行
                const toeval = this.$refs.toref.quill
                // eval() 函数可计算某个字符串，并执行其中的的 JavaScript 代码。
                // 获取光标所在位置
                const length = toeval.selection.savedRange.index
                // 插入图片，res为服务器返回的图片链接地址
                // 图片
                if (this.videoOrimg) {
                    toeval.insertEmbed(
                        length,
                        'image',
                        res.data
                    )
                } else {
                    toeval.insertEmbed(
                        length,
                        'video',
                        res.data
                    )
                }
                // 调整光标到最后
                toeval.setSelection(length + 1)
            } else {
                this.$message({ type: 'error', message: res.message });
                //定时2秒
                setTimeout(() => {
                    router.push({ path: '/login' })
                }, 1000);
            }
        },
        // 将内容上传给父组件
        postContent() {

            this.$emit('getContent', this.content)
        },

        onEditorBlur(v) {
            v.enable(!this.disabled)
        }, // 失去焦点事件
        onEditorFocus(v) {
            v.enable(!this.disabled)
        }, // 获得焦点事件
        init() {
            console.log('************************')
            const Parchment = Quill.import("parchment");
            class lineHeightAttributor extends Parchment.Attributor.Style { }

            const lineHeightStyle = new lineHeightAttributor(
                // "lineHeight",
                // "line-height",
                "lineHeight",
                "line-height",
                {
                    scope: Parchment.Scope.INLINE,
                    whitelist: ['1', '1.5', '1.75', '2', '3', '4']
                }
            );
            console.log('************************', lineHeightStyle)

            setTimeout(() => {
                Quill.register({ "formats/lineHeight": lineHeightStyle }, true);
                console.dir(Quill.register)
            }, 1000);

            // this.$refs.toref.register({ "formats/lineHeight": lineHeightStyle }, true);


        }
    },
    watch: {
        content: {
            immediate: true,    // 这句重要
            handler(val) {
                this.postContent()
            }
        },
        editorContent: {
            immediate: true,    // 这句重要
            handler(val) {
                this.content = this.contents
            }
        }
    }
}
</script>

<style scoped>
.upload-demo {
    display: none;
}

.quill-editor ::v-deep .ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value='default']::before,
.quill-editor ::v-deep .ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value='default']::before {
    content: '行高';
}

.quill-editor ::v-deep .ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value='1']::before,
.quill-editor ::v-deep .ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value='1']::before {
    content: '1';
}

.quill-editor ::v-deep .ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value='1.5']::before,
.quill-editor ::v-deep .ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value='1.5']::before {
    content: '1.5';
}

.quill-editor ::v-deep .ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value='1.75']::before,
.quill-editor ::v-deep .ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value='1.75']::before {
    content: '1.75';
}

.quill-editor ::v-deep .ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value='2']::before,
.quill-editor ::v-deep .ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value='2']::before {
    content: '2';
}

.quill-editor ::v-deep .ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value='3']::before,
.quill-editor ::v-deep .ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value='3']::before {
    content: '3';
}

.quill-editor ::v-deep .ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value='4']::before,
.quill-editor ::v-deep .ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value='4']::before {
    content: '4';
}

.quill-editor ::v-deep .ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value='5']::before,
.quill-editor ::v-deep .ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value='5']::before {
    content: '5';
}

.quill-editor ::v-deep .ql-snow .ql-picker.ql-lineheight {
    width: 70px;
}

.quill-editor ::v-deep .ql-snow .ql-editor .ql-lineheight-1 {
    line-height: 1;
}

.quill-editor ::v-deep .ql-snow .ql-editor .ql-lineheight-1-5 {
    line-height: 1.5;
}

.quill-editor ::v-deep .ql-snow .ql-editor .ql-lineheight-1-75 {
    line-height: 1.75;
}

.quill-editor ::v-deep .ql-snow .ql-editor .ql-lineheight-2 {
    line-height: 2;
}

.quill-editor ::v-deep .ql-snow .ql-editor .ql-lineheight-3 {
    line-height: 3;
}

.quill-editor ::v-deep .ql-snow .ql-editor .ql-lineheight-4 {
    line-height: 4;
}

.quill-editor ::v-deep .ql-snow .ql-editor .ql-lineheight-5 {
    line-height: 5;
}
</style>
