<template>
  <div>

    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right" style="width: 60%;">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>轮播图设置</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 搜索筛选 -->
    <el-form :inline="true" class="user-search" style="width: 60%;">

      <el-form-item>
        <el-button size="small" type="primary" icon="el-icon-plus" @click="handleEdit()"
          :disabled="isSort">添加</el-button>
        <el-button size="small" :type="isSort ? 'success' : 'warning'" icon=" el-icon-sort" @click="sort">{{
          isSort
            ? "保存" : "排序" }}</el-button>

      </el-form-item>
    </el-form>
    <!--列表-->
    <el-table size="small" :data="listData" highlight-current-row v-loading="loading" border
      element-loading-text="拼命加载中" style="width: 60%;">
      <el-table-column align="center" type="index" label="序号" min-width="100">
      </el-table-column>
      <el-table-column align="center" prop="imagePath" label="图片" min-width="100" show-overflow-tooltip>
        <template slot-scope="scope">
          <img :src="scope.row.imagePath" style="width: 100%;">
        </template>
      </el-table-column>
      <el-table-column align="center" prop="imagePath" label="图片地址" min-width="100" show-overflow-tooltip><template
          v-slot:default="{ row }">
          <div style="white-space: pre-wrap">{{ row.imagePath }}</div>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="sort" label="显示顺序" min-width="30" show-overflow-tooltip>
      </el-table-column>

      <el-table-column align="center" label="操作" min-width="80">
        <template slot-scope="scope">
          <el-button size="mini" type="success" @click="toUp(scope.$index, scope.row)" icon="el-icon-top" circle
            :disabled="!isSort"></el-button>
          <el-button size="mini" type="warning" @click="toDown(scope.$index, scope.row)" icon="el-icon-bottom" circle
            :disabled="!isSort"></el-button>
          <el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row)" icon="el-icon-delete"
            circle :disabled="isSort"></el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 编辑界面 -->
    <el-dialog :title="title" :visible.sync="editFormVisible" width="30%" @click="closeDialog('editForm')">

      <el-upload class="upload-demo" :action="uploadImagePath" :on-change="handleChange" :file-list="fileList"
        :on-success="handleSuccess" :headers="headers"
        accept="image/jpeg,image/png,image/gif,image/jpg,image/bmp,image/tiff,image/webp">
        <el-button size="small" type="primary">点击上传</el-button>
        <div slot="tip" class="el-upload__tip">只能上传jpg/png文件</div>
      </el-upload>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="closeDialog('editForm')">取消</el-button>
        <el-button size="small" type="primary" :loading="loading" class="title" @click="submitForm">保存</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import { addHomeImage, deleteHomeImage, editHomeImage, getHomeImages, getCategoryByCondition, addCategory, configCategory } from '../../api/configMG'
import { getUploadImagePath } from '../../utils/util'
import router from '../../router';
export default {
  data() {
    return {
      loading: false, //是显示加载
      editFormVisible: false, //控制编辑页面显示与隐藏
      configFormVisible: false,//配置页面显示与隐藏
      showcatelog: false,
      title: '添加',
      uploadImagePath: '',
      fileList: [],
      editForm: {
        id: null,
        categoryName: '',
        image: null
      },

      isSort: false,
      listData: [], //类目数据
      configList: []//某个类目的配置数组

    }
  },
  computed: {
    headers() {
      return {
        // 'Content-Type': 'multipart/form-data',
        'Authorization': localStorage.getItem('refreshToken')
      }
    }
  },

  /**
   * 数据发生改变
   */

  /**
   * 创建完毕
   */
  created() {

  },
  mounted() {
    this.uploadImagePath = getUploadImagePath()
    this.getdata()
  },
  /**
   * 里面的方法只有被调用才会执行
   */
  methods: {
    // 获取公司列表
    async getdata(parameter) {
      //this.loading = true
      this.listData = []
      let res = await getHomeImages()
      if (res.code === 200) {
        for (let i = 0; i < res.data.length; i++) {
          this.listData.push({ imagePath: res.data[i], sort: i + 1 })
        }
      }

    },


    //显示编辑界面
    handleEdit: function (index, row) {
      this.editFormVisible = true
      if (row != undefined && row != 'undefined') {
        this.title = '修改'
        this.editForm.id = row.id
        this.editForm.categoryName = row.categoryName
        this.editForm.image = row.image
      } else {
        this.title = '添加'
        this.editForm.id = null
        this.editForm.categoryName = ''
        this.editForm.image = null
      }
    },
    handleChange(file, fileList) {
      this.fileList = fileList
    },

    // 编辑、增加页面保存方法
    async submitForm() {
      let imageStr = []
      for (let item of this.fileList) {
        this.editForm.image = item.response.data
        imageStr.push(item.response.data)
      }
      this.params = { image: imageStr.join(',') }
      let res = await addHomeImage(this.params)
      if (res.code === 200) {
        this.$message({
          type: 'success',
          message: '保存成功！'
        });
        this.editFormVisible = false
        this.fileList = []
        this.getdata()
      } else {
        this.$message({ type: 'error', message: res.message });
      }
    },
    // 删除文章类目
    handleDelete(index, row) {
      this.$confirm('确定要删除吗?', '信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          let res = await deleteHomeImage({ id: row.sort })
          if (res.code === 200) {
            this.$message({
              type: 'success',
              message: '修改成功！'
            })
            this.getdata()
          } else {
            this.$message({
              type: 'error',
              message: res.message
            });
          }

        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    // 关闭编辑、增加弹出框
    closeDialog(formName) {
      this.editFormVisible = false
      // this.$refs[formName].resetFields()
      this.resetEditForm()
    },

    handleSuccess(response) {
      console.log("图片上传", response)
      if (response.code === 503) {
        this.$message({ type: 'error', message: response.message });
        //定时2秒
        setTimeout(() => {
          router.push({ path: '/login' })
        }, 1000);
      }
    },

    async sort() {
      this.isSort = !this.isSort
      if (!this.isSort) {
        let images = []
        for (let item of this.listData) {
          images.push(item.imagePath)
        }
        let res = await editHomeImage({ image: images.join(',') })
        if (res.code === 200) {
          this.$message({
            type: 'success',
            message: '排序成功！'
          })
          this.listData = []
          for (let i = 0; i < res.data.length; i++) {
            this.listData.push({ imagePath: res.data[i], sort: i + 1 })
          }
        } else {
          this.$message({
            type: 'error',
            message: res.message
          });
        }

      }
    },
    toUp(index, row) {
      if (index != 0) {
        let temp = JSON.parse(JSON.stringify(this.listData[index]))
        this.listData[index] = JSON.parse(JSON.stringify(this.listData[index - 1]))
        this.listData[index - 1] = temp
      }
    },
    toDown(index, row) {
      if (index != this.listData.length - 1) {
        let temp = JSON.parse(JSON.stringify(this.listData[index]))
        this.listData[index] = JSON.parse(JSON.stringify(this.listData[index + 1]))
        this.listData[index + 1] = temp
      }
    },
  }
}
</script>

<style scoped>
.user-search {
  margin-top: 20px;
}

.userRole {
  width: 100%;
}

.avatar-uploader {
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  width: 80px;
  height: 80px;
  overflow: hidden;
}

.avatar-uploader-border {
  border: 1px dashed #8c939d;
}

.avatar-uploader:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
}

.avatar-container {
  display: flex;
  line-height: 80px;
  margin-left: 49px;
  margin-bottom: 10px;
}

.avatar {
  width: 80px;
  height: 80px;
  display: block;
}

.word-wrap-cell {
  word-wrap: break-word;
  white-space: normal;
  overflow-wrap: break-word;
}
</style>