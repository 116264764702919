<template>
  <div>

    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right" style="width: 60%;">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>文章类目列表</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 搜索筛选 -->
    <el-form :inline="true" :model="formInline" class="user-search" style="width: 100%;">
      <el-form-item label="搜索">
        <el-input size="small" v-model="formInline.categoryName" placeholder="输入类目名称" clearable=""></el-input>
      </el-form-item>
      <el-form-item>
        <el-date-picker v-model="formInline.date" @change="dateChange" type="daterange" range-separator="至"
          start-placeholder="开始日期" end-placeholder="结束日期" size="small" value-format="yyyy-MM-dd HH:mm:ss"
          :default-time="['00:00:00', '23:59:59']">
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button size="small" type="primary" icon="el-icon-search" @click="search">搜索</el-button>
        <el-button size="small" type="info" icon="el-icon-refresh-right" @click="reserFormInLine">重置</el-button>
        <el-button size="small" type="primary" icon="el-icon-plus" @click="handleEdit()">添加</el-button>
      </el-form-item>
    </el-form>
    <!--列表-->
    <el-table size="small" :data="listData" highlight-current-row v-loading="loading" border
      element-loading-text="拼命加载中" style="width: 90%;">
      <el-table-column align="center" type="index" label="序号" min-width="100">
      </el-table-column>
      <el-table-column align="center" prop="image" label="类目图标" min-width="50" show-overflow-tooltip>
        <template slot-scope="scope">
          <img :src="scope.row.image" style="width: 50px; height: 50px;">
        </template>
      </el-table-column>
      <el-table-column align="center" prop="categoryName" label="类目名称" min-width="60" show-overflow-tooltip>
      </el-table-column>
      <el-table-column align="center" prop="createTime" label="创建时间" mi-width="100" show-overflow-tooltip>
        <template slot-scope="scope">
          <div>{{ scope.row.createTime | timestampToDate }}</div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" width="400">
        <template slot-scope="scope">
          <el-button size="mini" type="primary" @click="handleShow(scope.$index, scope.row)">查看</el-button>
          <el-button size="mini" type="success" @click="handleConfig(scope.$index, scope.row)">表单配置</el-button>
          <el-button size="mini" type="warning" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
          <el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页组件 -->
    <el-pagination @current-change="handleCurrentChange" :hide-on-single-page="true"
      :current-page.sync="formInline.page" :page-count="formInline.totalPage" layout="prev, pager, next, jumper"
      :total="formInline.total">
    </el-pagination>
    <!-- 编辑界面 -->
    <el-dialog :title="title" :visible.sync="editFormVisible" width="30%" @click="closeDialog('editForm')">
      <div class="avatar-container">上传图标&nbsp;&nbsp;&nbsp;&nbsp;
        <el-upload class="avatar-uploader" :class="editForm.image ? '' : 'avatar-uploader-border'"
          :action="uploadImagePath" :show-file-list="false" :headers="headers" :on-success="handleAvatarSuccess"
          :before-upload="beforeAvatarUpload">
          <img v-if="editForm.image" :src="editForm.image" class="avatar">
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </div>
      <el-form label-width="120px" :model="editForm" :rules="rules" ref="editForm">
        <el-form-item label="类目名称" prop="categoryName">
          <el-input size="small" v-model="editForm.categoryName" auto-complete="off" placeholder="请输入渠道名称"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="closeDialog('editForm')">取消</el-button>
        <el-button size="small" type="primary" :loading="loading" class="title"
          @click="submitForm('editForm')">保存</el-button>
      </div>
    </el-dialog>
    <!-- 配置弹窗 -->
    <el-dialog :title="title" :visible.sync="configFormVisible" width="30%" @click="closeDialog('editForm')">
      <div v-if="showcatelog" style="margin-bottom: 10px;">
        <div>类目名称：&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          {{ editForm.categoryName }}</div>
        <div style="display: flex;line-height: 80px;margin-top: 20px;">类目图标：
          <img :src="editForm.image" class="avatar-container avatar">
        </div>

      </div>

      <el-table size="small" :data="configList" highlight-current-row v-loading="loading" border
        element-loading-text="拼命加载中">
        <el-table-column align="center" prop="name" label="表单项" min-width="50">
        </el-table-column>
        <el-table-column align="center" prop="isEnable" label="是否启用" min-width="20">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.isEnable" @change="switchChange(scope.row)" :disabled="showcatelog">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="isEnable" label="是否必填" min-width="20">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.isNecessary" :disabled="!scope.row.isEnable || showcatelog">
            </el-switch>
          </template>
        </el-table-column>

      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="closeConfigDialog">{{ showcatelog ? '关闭' : '取消' }}</el-button>
        <el-button v-if="!showcatelog" size="small" type="primary" :loading="loading" class="title"
          @click="submitConfigForm">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { editCategory, deleteCategory, getCategoryByCondition, addCategory, configCategory } from '../../api/configMG'
import { getUploadImagePath } from '../../utils/util'
import router from '../../router';
export default {
  data() {
    return {
      loading: false, //是显示加载
      editFormVisible: false, //控制编辑页面显示与隐藏
      configFormVisible: false,//配置页面显示与隐藏
      showcatelog: false,
      title: '添加',
      uploadImagePath: '',
      editForm: {
        id: null,
        categoryName: '',
        image: null
      },
      // rules表单验证
      rules: {
        categoryName: [{ required: true, message: '请输入渠道名称', trigger: 'blur' }],

      },
      formInline: {
        page: 1,
        totalPage: 1,
        total: 0,
        categoryName: '',
        image: null,
        date: [],
        startDate: '',
        endDate: '',
        hasName: 0,
        hasEmail: 0,
        hasCompany: 0,
        hasPhoneNumber: 0,
        hasCountryDpd: 0,
        hasCity: 0,
        hasIndustry: 0
      },

      listData: [], //类目数据
      configList: []//某个类目的配置数组

    }
  },
  computed: {
    headers() {
      return {
        // 'Content-Type': 'multipart/form-data',
        'Authorization': localStorage.getItem('refreshToken')
      }
    }
  },

  /**
   * 数据发生改变
   */

  /**
   * 创建完毕
   */
  created() {

  },
  mounted() {
    this.uploadImagePath = getUploadImagePath()
    this.getdata()
  },
  /**
   * 里面的方法只有被调用才会执行
   */
  methods: {
    // 获取公司列表
    async getdata(parameter) {
      //this.loading = true
      let res = await getCategoryByCondition(this.formInline)
      if (res.data && res.code === 200) {
        this.listData = res.data.categoryList
        this.formInline.totalPage = res.data.totalPage
        this.formInline.total = res.data.total
      }
    },
    //日期选择器中日期变化时触发
    dateChange() {
      if (this.formInline.date) {
        this.formInline.startDate = this.formInline.date[0]
        this.formInline.endDate = this.formInline.date[1]
      } else {
        this.formInline.startDate = ''
        this.formInline.endDate = ''
      }

    },

    handleCurrentChange() {
      this.getdata()
    },
    // 搜索事件
    async search() {
      this.getdata(this.formInline)
    },
    //显示编辑界面
    handleEdit: function (index, row) {
      this.editFormVisible = true
      if (row != undefined && row != 'undefined') {
        this.title = '修改'
        this.editForm.id = row.id
        this.editForm.categoryName = row.categoryName
        this.editForm.image = row.image
      } else {
        this.title = '添加'
        this.editForm.id = null
        this.editForm.categoryName = ''
        this.editForm.image = null
      }
    },
    handleConfig(index, row) {
      this.editForm.id = row.id
      this.configFormVisible = true
      this.title = '配置'
      this.configList = []
      this.configList.push({ hasCity: row.hasCity, name: '所在城市', isEnable: row.hasCity == 0 ? false : true, isNecessary: row.hasCity == 2 ? true : false })
      this.configList.push({ hasCompany: row.hasCompany, name: '公司名称', isEnable: row.hasCompany == 0 ? false : true, isNecessary: row.hasCompany == 2 ? true : false })
      this.configList.push({ hasCountryDpd: row.hasCountryDpd, name: '国家/地区', isEnable: row.hasCountryDpd == 0 ? false : true, isNecessary: row.hasCountryDpd == 2 ? true : false })
      this.configList.push({ hasEmail: row.hasEmail, name: '邮箱地址', isEnable: row.hasEmail == 0 ? false : true, isNecessary: row.hasEmail == 2 ? true : false })
      this.configList.push({ hasIndustry: row.hasIndustry, name: '所在行业', isEnable: row.hasIndustry == 0 ? false : true, isNecessary: row.hasIndustry == 2 ? true : false })
      this.configList.push({ hasName: row.hasName, name: `姓\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0名`, isEnable: row.hasName == 0 ? false : true, isNecessary: row.hasName == 2 ? true : false })
      this.configList.push({ hasPhoneNumber: row.hasPhoneNumber, name: '手机号码', isEnable: row.hasPhoneNumber == 0 ? false : true, isNecessary: row.hasPhoneNumber == 2 ? true : false })
    },
    handleShow(index, row) {
      this.showcatelog = true
      this.editForm.id = row.id
      this.editForm.categoryName = row.categoryName
      this.editForm.image = row.image
      this.handleConfig(index, row)
    },
    // 编辑、增加页面保存方法
    submitForm(editData) {
      this.$refs[editData].validate(async valid => {

        if (valid) {
          if (!this.editForm.image) {
            this.$message({
              type: 'error',
              message: '请上传图片！'
            })
            return
          }
          if (!this.editForm.id) {
            this.editForm.id = ""
            let res = await addCategory(this.editForm)
            if (res.code === 200) {
              this.$message({
                type: 'success',
                message: '添加成功！'
              })
              this.resetEditForm()
              this.formInline.page = 1
              this.getdata(this.formInline)
            } else {
              this.$message({
                type: 'error',
                message: res.message
              });
            }
          } else {
            let res = await editCategory(this.editForm)
            if (res.code === 200) {
              this.$message({
                type: 'success',
                message: '修改成功！'
              })
              this.resetEditForm()
              this.getdata(this.formInline)
            } else {
              this.$message({
                type: 'error',
                message: res.message
              });
            }
          }
          this.editFormVisible = false
          this.$refs[editData].resetFields()
        } else {
          return false
        }
      })
    },// 编辑、增加页面保存方法
    async submitConfigForm() {
      let params = {}
      for (let item of this.configList) {
        for (let key in item) {
          if (key != 'name' && key != 'isNecessary' && key != 'isEnable') {
            params[key] = item.isNecessary ? 2 : item.isEnable ? 1 : 0
          }
        }
      }
      params.categoryId = this.editForm.id
      let res = await configCategory(params)
      if (res.code === 200) {
        this.$message({
          type: 'success',
          message: '配置成功！'
        });
        this.getdata()
      } else {
        this.$message({ type: 'error', message: res.message });
      }
      this.configList = []
      this.editForm.id = null
      this.configFormVisible = false
      this.showcatelog = false
      this.resetEditForm()
    },
    // 删除文章类目
    handleDelete(index, row) {
      this.$confirm('确定要删除吗?', '信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          let res = await deleteCategory({ categoryId: row.id })
          if (res.code === 200) {
            this.$message({
              type: 'success',
              message: '修改成功！'
            })
            this.formInline.page = 1
            this.getdata(this.formInline)
          } else {
            this.$message({
              type: 'error',
              message: res.message
            });
          }

        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    // 关闭编辑、增加弹出框
    closeDialog(formName) {
      this.editFormVisible = false
      // this.$refs[formName].resetFields()
      this.resetEditForm()
    },
    closeConfigDialog(formName) {
      this.configFormVisible = false
      this.showcatelog = false
      // this.$refs[formName].resetFields()
      this.resetEditForm()
    },
    beforeAvatarUpload() {

    },
    handleAvatarSuccess(response) {
      if (response.code === 200) {
        this.editForm.image = response.data
        this.$message({
          type: 'success',
          message: '图片上传成功！'
        })
      } else {
        this.$message({ type: 'error', message: response.message });
        //定时2秒
        setTimeout(() => {
          router.push({ path: '/login' })
        }, 1000);
      }
    },
    resetEditForm() {
      this.editForm.id = ''
      this.editForm.categoryName = ''
      this.editForm.image = null
    },
    switchChange(row) {
      if (!row.isEnable) {
        row.isNecessary = false
      }
    },
    reserFormInLine() {
      this.formInline = {
        page: 1,
        totalPage: 1,
        total: 0,
        categoryName: '',
        image: null,
        date: [],
        startDate: '',
        endDate: '',
        hasName: 0,
        hasEmail: 0,
        hasCompany: 0,
        hasPhoneNumber: 0,
        hasCountryDpd: 0,
        hasCity: 0,
        hasIndustry: 0
      }
    }
  }
}
</script>

<style scoped>
.user-search {
  margin-top: 20px;
}

.userRole {
  width: 100%;
}

.avatar-uploader {
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  width: 80px;
  height: 80px;
  overflow: hidden;
}

.avatar-uploader-border {
  border: 1px dashed #8c939d;
}

.avatar-uploader:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
}

.avatar-container {
  display: flex;
  line-height: 80px;
  margin-left: 49px;
  margin-bottom: 10px;
}

.avatar {
  width: 80px;
  height: 80px;
  display: block;
}
</style>