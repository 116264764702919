<template>
  <div>

    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right" style="width: 60%;margin-bottom: 30px;">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>等级设置</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 搜索筛选 -->
    <!-- <el-form :inline="true" :model="formInline" class="user-search" style="width: 60%;">
      <el-form-item label="搜索：">
        <el-input size="small" v-model="formInline.channelName" placeholder="输入渠道名称"></el-input>
      </el-form-item>
      <el-form-item>
        <el-date-picker v-model="formInline.date" @change="dateChange" type="daterange" range-separator="至"
          start-placeholder="开始日期" end-placeholder="结束日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button size="small" type="primary" icon="el-icon-search" @click="search">搜索</el-button>
        <el-button size="small" type="primary" icon="el-icon-plus" @click="handleEdit()">添加</el-button>
      </el-form-item>
    </el-form> -->
    <!--列表-->
    <el-table size="small" :data="listData" highlight-current-row v-loading="loading" border
      element-loading-text="拼命加载中" style="width: 60%;">
      <el-table-column align="center" type="index" label="序号" min-width="10">
      </el-table-column>
      <el-table-column align="center" prop="info" label="等级" min-width="40" show-overflow-tooltip>
      </el-table-column>
      <el-table-column align="center" prop="rule" label="等级规则" mi-width="200" show-overflow-tooltip>

      </el-table-column>
      <el-table-column align="center" label="操作" min-width="60">
        <template slot-scope="scope">
          <el-button size="mini" type="warning" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
          <!-- <el-button size="mini" type="danger" @click="deleteUser(scope.$index, scope.row)">删除</el-button> -->
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页组件 -->
    <el-pagination @current-change="handleCurrentChange" :hide-on-single-page="true"
      :current-page.sync="formInline.page" :page-count="formInline.totalPage" layout="prev, pager, next, jumper"
      :total="formInline.total">
    </el-pagination>
    <!-- 编辑界面 -->
    <el-dialog :title="title" :visible.sync="editFormVisible" width="400px" @click="closeDialog('editForm')">
      <el-form label-width="100px" :model="editForm" :rules="rules" ref="editForm">
        <el-form-item label="文章浏览数" prop="credit">{{ editForm.id == 2 ? '&nbsp;&nbsp;&nbsp;&nbsp;大于' : '' }}
          <!-- <el-input size="small" v-model="editForm.credit" auto-complete="off" placeholder="请输入积分"></el-input> -->
          <el-input-number size="mini" v-model="editForm.articleRuleStart" style="width: 100px;"></el-input-number>
          {{ editForm.id == 2 ? '' : ' 至 ' }}
          <el-input-number v-if="editForm.id != 2" size="mini" v-model="editForm.articleRuleEnd"
            style="width: 100px;"></el-input-number>
        </el-form-item>
        <el-form-item label="附件下载数" prop="credit">{{ editForm.id == 2 ? '&nbsp;&nbsp;&nbsp;&nbsp;大于' : '' }}
          <!-- <el-input size="small" v-model="editForm.credit" auto-complete="off" placeholder="请输入积分"></el-input> -->
          <el-input-number size="mini" v-model="editForm.downloadRuleStart" style="width: 100px;"></el-input-number>{{
            editForm.id == 2 ? '' : ' 至 ' }}
          <el-input-number v-if="editForm.id != 2" size="mini" v-model="editForm.downloadRuleEnd"
            style="width: 100px;"></el-input-number>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="closeDialog()">取消</el-button>
        <el-button size="small" type="primary" :loading="loading" class="title" @click="submitForm()">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getLevelRules, editLevelRules } from '../../api/configMG'
export default {
  data() {
    return {
      loading: false, //是显示加载
      editFormVisible: false, //控制编辑页面显示与隐藏
      title: '添加',

      editForm: {
        id: null,
        info: '',
        articleRuleStart: '',
        articleRuleEnd: '',
        downloadRuleStart: '',
        downloadRuleEnd: ''
      },
      // rules表单验证
      rules: {
        credit: [{ required: true, message: '请输入积分', trigger: 'blur' }],

      },
      formInline: {
        page: 1,
        totalPage: 1,
        total: 0,
        channelName: '',
        date: [],
        startDate: '',
        endDate: '',
      },

      listData: [], //用户数据
      // 分页参数
      pageparm: {
        currentPage: 1,
        pageSize: 10,
        total: 10
      }
    }
  },

  /**
   * 数据发生改变
   */

  /**
   * 创建完毕
   */
  created() {

  },
  mounted() {
    this.getdata()
  },
  /**
   * 里面的方法只有被调用才会执行
   */
  methods: {
    // 获取公司列表
    async getdata(parameter) {
      //this.loading = true
      let res = await getLevelRules()
      if (res.data && res.code === 200) {
        this.listData = res.data
        for (let item of this.listData) {
          if (item.id === 2) {
            item.rule = "浏览文章数 >" + item.article + "，或 下载次数 >" + item.download
          } else {
            let articleStr = item.article.split('-')
            item.articleRuleStart = articleStr[0]
            item.articleRuleEnd = articleStr[1]
            let downloadStr = item.download.split('-')
            item.downloadRuleStart = downloadStr[0]
            item.downloadRuleEnd = downloadStr[1]
            item.rule = "浏览文章数 " + item.articleRuleStart + " - " + item.articleRuleEnd + "，或 下载次数 " + item.downloadRuleStart + " - " + item.downloadRuleEnd
          }
        }
        this.formInline.totalPage = res.data.totalPage
        this.formInline.total = res.data.total
      }
    },
    //日期选择器中日期变化时触发
    dateChange() {
      this.formInline.startDate = this.formInline.date[0]
      this.formInline.endDate = this.formInline.date[1]
    },

    handleCurrentChange() {
      this.getdata()
    },
    // 搜索事件
    async search() {
      this.getdata(this.formInline)
    },
    //显示编辑界面
    handleEdit: function (index, row) {
      this.editFormVisible = true
      if (row != undefined && row != 'undefined') {
        this.title = row.info
        this.editForm.id = row.id
        if (row.id === 2) {
          this.editForm.articleRuleStart = row.article
          this.editForm.downloadRuleStart = row.download
        } else {
          this.editForm.articleRuleStart = row.articleRuleStart
          this.editForm.articleRuleEnd = row.articleRuleEnd
          this.editForm.downloadRuleStart = row.downloadRuleStart
          this.editForm.downloadRuleEnd = row.downloadRuleEnd
        }
        this.editForm.info = row.info
      }
    },
    // 编辑、增加页面保存方法
    async submitForm() {
      if (this.editForm.id === 2) {
        this.editForm.article = this.editForm.articleRuleStart + ''
        this.editForm.download = this.editForm.downloadRuleStart + ''
      } else {
        this.editForm.article = this.editForm.articleRuleStart + '-' + this.editForm.articleRuleEnd
        this.editForm.download = this.editForm.downloadRuleStart + '-' + this.editForm.downloadRuleEnd
      }
      let res = await editLevelRules(this.editForm)
      if (res.code === 200) {
        this.$message({
          type: 'success',
          message: '修改成功！'
        })
        this.getdata()
      } else {
        this.$message({
          type: 'error',
          message: res.message
        });
      }
      this.editFormVisible = false
      this.editForm.id = ''
      this.editForm.articleRuleStart = ''
      this.editForm.articleRuleEnd = ''
      this.editForm.downloadRuleStart = ''
      this.editForm.downloadRuleEnd = ''
      this.editForm.article = ''
      this.editForm.download = ''
    },

    // 关闭编辑、增加弹出框
    closeDialog() {
      this.editFormVisible = false
      this.editForm.id = ''
      this.editForm.articleRule.length = 0
      this.editForm.downloadRule.length = 0
    }
  }
}
</script>

<style scoped>
.user-search {
  margin-top: 20px;
}

.userRole {
  width: 100%;
}
</style>