<template>
    <div class="login-wrap">
    </div>
</template>

<script>

export default {
    data() {
        return {
            channel: '',
            channelId: 0,
            sceneId: 0,
            redirect: false
        }
    },
    computed: {

    },


    created() {
        // console.log(this.$route.query)
        // if (this.$route.query.channel && this.$route.query.channelId && this.$route.query.sceneId) {
        //     this.channel = this.$route.query.channel
        //     this.channelId = this.$route.query.channelId
        //     this.sceneId = this.$route.query.sceneId
        //     this.redirect = true
        // }

    },

    mounted() {
        console.log(this.$route.query)
        if (this.$route.query.channelId && this.$route.query.sceneId) {
            this.channelId = this.$route.query.channelId
            this.sceneId = this.$route.query.sceneId
            this.redirect = true
        }
        if (this.redirect) {
            this.goToWx()

        }
    },

    methods: {
        goToWx() {
            window.location.href = 'weixin://dl/business/?appid=wx9eee34db9f7e52cc&path=pages/article/article&query=query=' + this.sceneId + ',' + this.channelId;
        }
    }
}
</script>

<style scoped>
.login-wrap {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding-top: 10%;
    background-color: #ffffff;
    background-repeat: no-repeat;
    background-position: center right;
    background-size: 100%;
}
</style>