<template>
  <div>

    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right" style="width: 60%;">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>订单列表</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 搜索筛选 -->
    <el-form :inline="true" :model="formInline" class="user-search" style="width: 100%;">
      <el-form-item label="搜索：">
        <el-input size="small" v-model="formInline.title" placeholder="输入礼物名称" style="width:150px" clearable></el-input>
      </el-form-item>

      <el-form-item style="width: 110px;">
        <el-select v-model="formInline.state" placeholder="订单状态" size="small" clearable>
          <el-option v-for="item in stateList" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-date-picker v-model="formInline.date" @change="dateChange" type="daterange" range-separator="至"
          start-placeholder="开始日期" end-placeholder="结束日期" size="small">
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button size="small" type="primary" icon="el-icon-search" @click="search">搜索</el-button>
        <el-button size="small" type="info" icon="el-icon-refresh-right" @click="reserFormInLine">重置</el-button>
      </el-form-item>
    </el-form>
    <!--列表-->
    <el-table size="small" :data="listData" highlight-current-row v-loading="loading" border
      element-loading-text="拼命加载中" style="width: 90%;">
      <el-table-column align="center" type="index" label="序号" min-width="100">
      </el-table-column>
      <el-table-column align="center" prop="image" label="图片" min-width="50" show-overflow-tooltip>
        <template slot-scope="scope">
          <img :src="scope.row.image" style="width: 50px; height: 50px;">
        </template>
      </el-table-column>
      <!-- <el-table-column align="center" prop="articleTitle" label="文章标题" min-width="60" show-overflow-tooltip>
      </el-table-column> -->
      <el-table-column align="center" prop="title" label="礼物标题" min-width="60" show-overflow-tooltip>
      </el-table-column>
      <el-table-column align="center" prop="userName" label="下单人" min-width="60" show-overflow-tooltip>
      </el-table-column>
      <el-table-column align="center" prop="recipientName" label="收件人姓名" min-width="60" show-overflow-tooltip>
      </el-table-column>
      <el-table-column align="center" prop="contacts" label="收件人电话" min-width="60" show-overflow-tooltip>
      </el-table-column>
      <el-table-column align="center" prop="credits" label="所需积分" min-width="60" show-overflow-tooltip>
      </el-table-column>
      <el-table-column align="center" prop="isDelivery" label="状态" min-width="60" show-overflow-tooltip>
        <template slot-scope="scope">
          <div>{{ scope.row.isDelivery == 0 ? '未发货' : '已发货' }}</div>
        </template>
      </el-table-column>

      <el-table-column align="center" prop="createTime" label="下单时间" min-width="50" show-overflow-tooltip>
        <template slot-scope="scope">
          <div>{{ scope.row.createTime | timestampToDate }}</div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" width="150">
        <template slot-scope="scope">
          <el-button size="mini" type="primary" @click="handleShow(scope.$index, scope.row)">查看</el-button>
          <el-button :disabled="scope.row.isDelivery ? true : false" size="mini" type="success"
            @click="handleDeliver(scope.$index, scope.row)">发货</el-button>
          <!-- <el-button size="mini" type="warning" @click="handleEdit(scope.$index, scope.row)">编辑</el-button> -->
          <!-- <el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row)">删除</el-button> -->
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页组件 -->
    <el-pagination @current-change="handleCurrentChange" :hide-on-single-page="true"
      :current-page.sync="formInline.page" :page-count="formInline.totalPage" layout="prev, pager, next, jumper"
      :total="formInline.total">
    </el-pagination>
    <!-- 编辑界面 -->
    <el-dialog :title="title" :visible.sync="editFormVisible" width="390px" @click="closeDialog('editForm')">

      <el-form label-width="120px" :model="editForm" :rules="rules" ref="editForm">
        <el-form-item label="物流种类" prop="transportation">
          <el-select v-model="editForm.transportation" placeholder="请选择物流种类" size="small">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="物流单号" prop="trackingNumber">
          <el-input size="small" v-model="editForm.trackingNumber" auto-complete="off" placeholder="请输入快递单号"
            style="width:90%;"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="closeDialog('editForm')">取消</el-button>
        <el-button v-if="!showGift" size="small" type="primary" :loading="loading" class="title"
          @click="submitForm('editForm')">保存</el-button>
      </div>
    </el-dialog>

    <!-- 查看界面 -->
    <el-dialog :title="title" :visible.sync="showFormVisible" width="650px" @click="closeDialog('editForm')">
      <el-image :src="order.image" class="order-image"></el-image>
      <el-descriptions title="礼品信息">
        <el-descriptions-item label="礼品标题">{{ order.title }}</el-descriptions-item>
        <el-descriptions-item label="兑换积分">{{ order.credits }}</el-descriptions-item>
      </el-descriptions>
      <el-divider></el-divider>
      <el-descriptions title="收件人信息">
        <el-descriptions-item label="收件人">{{ order.recipientName }}</el-descriptions-item>
        <el-descriptions-item label="手机号">{{ order.contacts }}</el-descriptions-item>
        <el-descriptions-item label="下单用户">{{ order.username }}
        </el-descriptions-item>
        <el-descriptions-item label="下单时间">{{ order.createTime | timestampToDate }}
        </el-descriptions-item>
        <el-descriptions-item label="收件地址">{{ order.mailingAddress }}</el-descriptions-item>
      </el-descriptions>
      <el-divider></el-divider>
      <el-descriptions title="物流信息">
        <el-descriptions-item label="物流方式">{{ order.transportation }}</el-descriptions-item>
        <el-descriptions-item label="物流单号">{{ order.trackingNumber }}</el-descriptions-item>
      </el-descriptions>
    </el-dialog>

  </div>
</template>

<script>
import Editor from '../../components/Editor'
import { getOrderByCondition, orderDelivery } from '../../api/orderMG'
import { getUploadImagePath } from '../../utils/util'
export default {
  data() {
    return {
      editorRef: 'test',
      editorContent: '',
      loading: false, //是显示加载
      editFormVisible: false, //控制编辑页面显示与隐藏
      showFormVisible: false,//查看页面显示与隐藏
      showGift: false,
      title: '添加',
      uploadImagePath: '',
      editForm: {
        id: null,
        trackingNumber: '',
        transportation: '',
      },
      // rules表单验证
      rules: {
        transportation: [{ required: true, message: '请选择物流方式', trigger: 'blur' }],
        trackingNumber: [{ required: true, message: '请输入快递单号', trigger: 'blur' }],
      },
      formInline: {
        page: 1,
        totalPage: 1,
        total: 0,
        title: '',
        username: '',
        recipientName: '',
        contacts: '',
        date: [],
        startDate: '',
        endDate: '',
        userId: '',
        pageSize: 10,
        state: ''
      },
      order: {
        title: '',
        credits: null,
        recipientName: '',
        contacts: '',
        username: '',
        createTime: '',
        mailingAddress: '',
        transportation: '',
        trackingNumber: ''
      },
      stateList: [{ value: 0, label: '未发货' }, { value: 1, label: '已发货' }],
      options: [{
        value: '顺丰快运',
        label: '顺丰快运'
      }, {
        value: '申通快递',
        label: '申通快递'
      }, {
        value: '圆通快递',
        label: '圆通快递'
      }, {
        value: '韵达快运',
        label: '韵达快运'
      }, {
        value: '邮政EMS',
        label: '邮政EMS'
      }, {
        value: '京东物流',
        label: '京东物流'
      }, {
        value: '百世汇通',
        label: '百世汇通'
      }, {
        value: '中通快递',
        label: '中通快递'
      }, {
        value: 'FexEx联邦快递',
        label: 'FexEx联邦快递'
      }, {
        value: 'DHL国际快递',
        label: 'DHL国际快递'
      }, {
        value: '其他',
        label: '其他'
      }],

      listData: [], //类目数据
      configList: []//某个类目的配置数组

    }
  },
  components: { Editor },


  /**
   * 数据发生改变
   */

  /**
   * 创建完毕
   */
  created() {

  },

  mounted() {
    if (this.$route.query.userId) {
      this.formInline.userId = this.$route.query.userId
    }
    this.uploadImagePath = getUploadImagePath()
    this.getdata()
  },
  /**
   * 里面的方法只有被调用才会执行
   */
  methods: {
    // 获取公司列表
    async getdata(parameter) {
      //this.loading = true
      let res = await getOrderByCondition(this.formInline)
      if (res.data && res.code === 200) {

        this.listData = res.data.records



        this.formInline.totalPage = res.data.pages
        this.formInline.total = res.data.total
      }
    },
    //日期选择器中日期变化时触发
    dateChange() {
      if (this.formInline.date) {
        this.formInline.startDate = this.formInline.date[0]
        this.formInline.endDate = this.formInline.date[1]
      } else {
        this.formInline.startDate = ''
        this.formInline.endDate = ''
      }

    },

    handleCurrentChange() {
      this.getdata()
    },
    // 搜索事件
    async search() {
      this.getdata(this.formInline)
    },
    //显示编辑界面
    handleEdit: function (index, row) {
      this.editFormVisible = true
      this.showGift = false
      if (row != undefined && row != 'undefined') {
        this.title = '修改'
        this.editForm.id = row.id
        this.editForm.title = row.title
        this.editForm.images = row.images
        this.editForm.credits = row.credits
      } else {
        this.title = '添加'
        this.editForm.id = null
        this.editForm.categoryName = ''
        this.editForm.image = null
      }
    },

    handleDeliver(index, row) {
      this.editFormVisible = true
      this.title = '发货'
      this.editFormVisible = true
      this.editForm.id = row.id
      // this.editForm.title = row.title
      // this.editForm.images = row.images
      // this.editForm.credits = row.credits
    },
    // 编辑、增加页面保存方法
    submitForm(editData) {

      this.$refs[editData].validate(async valid => {
        if (valid) {
          if (this.editForm.id) {
            let res = await orderDelivery(this.editForm)
            if (res.code === 200) {
              this.$message({
                type: 'success',
                message: '修改成功！'
              })
              this.resetEditForm()
              this.getdata(this.formInline)
            } else {
              this.$message({
                type: 'error',
                message: res.message
              });
            }
          }
          this.editFormVisible = false
        } else {
          return false
        }
      })
    },

    // 删除文章类目
    handleDelete(index, row) {
      this.$confirm('确定要删除吗?', '信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          let res = await deleteGift({ id: row.id })
          if (res.code === 200) {
            this.$message({
              type: 'success',
              message: '修改成功！'
            })
            this.formInline.page = 1
            this.getdata(this.formInline)
          } else {
            this.$message({
              type: 'error',
              message: res.message
            });
          }

        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    // 关闭编辑、增加弹出框
    closeDialog(formName) {
      this.editFormVisible = false
      // this.$refs[formName].resetFields()
      this.resetEditForm()
    },

    resetEditForm() {
      this.editForm.id = ''
      this.editForm.title = ''
      this.editForm.trackingNumber = ''
      this.editForm.transportation = ''
    },
    switchChange(row) {
      if (!row.isEnable) {
        row.isNecessary = false
      }
    },
    deleteImage(images, index) {
      if (this.showGift) {
        return
      }
      this.$confirm('确定删除图片?', '信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          images.splice(index, 1)
        })
        .catch(() => {

        })

    },
    handleShow(index, row) {
      this.title = '查看'
      this.showFormVisible = true
      this.order.title = row.title
      this.order.image = row.image
      this.order.id = row.id
      this.order.createTime = row.createTime
      this.order.contacts = row.contacts
      this.order.credits = row.credits
      this.order.mailingAddress = row.mailingAddress
      this.order.recipientName = row.recipientName
      this.order.trackingNumber = row.trackingNumber
      this.order.transportation = row.transportation
      this.order.username = row.userName
    },
    closeShowDialog() {
      this.showFormVisible = false
    },
    reserFormInLine() {
      this.formInline = {
        page: 1,
        totalPage: this.formInline.totalPage,
        total: this.formInline.total,
        pageSize: 10,
        userId: this.formInline.userId,
        state: '',
        title: '',
        username: '',
        recipientName: '',
        contacts: '',
        date: [],
        startDate: '',
        endDate: '',
      }
    }
  }
}
</script>

<style scoped>
.user-search {
  margin-top: 20px;
}

.userRole {
  width: 100%;
}

.avatar-uploader {
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  width: 80px;
  height: 80px;
  overflow: hidden;
}

.avatar-uploader-border {
  border: 1px dashed #8c939d;
}

.avatar-uploader:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
}

.avatar-container {
  display: flex;
  line-height: 80px;
  margin-left: 49px;
  margin-bottom: 10px;
}

.avatar {
  width: 80px;
  height: 80px;
  display: block;
}

.m-r-10 {
  margin-right: 10px;
}

.order-image {
  width: 80px;
  height: 80px;
  right: 60px;
  top: 60px;
  position: absolute;
}
</style>