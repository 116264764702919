/**
* 左边菜单
*/
<template>
  <el-menu default-active="2" :collapse="collapsed" collapse-transition router :default-active="$route.path"
    unique-opened class="el-menu-vertical-demo" background-color="#051f24" text-color="#fff"
    active-text-color="#F0F0F0">
    <div class="logobox">
      <!-- <img class="logoimg" src="../assets/img/logo.png" alt=""> -->
    </div>
    <el-menu-item index="/statistics/DataOverview" style="home-gbc">
      <i class="el-icon-setting"></i>
      <span slot="title">首页</span>
    </el-menu-item>
    <el-submenu v-for="menu in allmenu" :key="menu.menuid" :index="menu.menuname">
      <template slot="title">
        <i :class="menu.icon"></i>
        <span>{{ menu.menuname }}</span>
      </template>
      <el-menu-item-group>
        <el-menu-item v-for="chmenu in menu.menus" :index="'/' + chmenu.url" :key="chmenu.menuid">
          <!-- <i class="iconfont" :class="chmenu.icon"></i> -->
          <i :class="chmenu.icon"></i>
          <span>{{ chmenu.menuname }}</span>
        </el-menu-item>
      </el-menu-item-group>
    </el-submenu>
  </el-menu>
</template>
<script>
import { menu } from '../api/userMG'
export default {
  name: 'leftnav',
  data() {
    return {
      collapsed: false,
      allmenu: []
    }
  },
  // 创建完毕状态(里面是操作)
  created() {
    // 获取图形验证码
    let res = {
      success: true,
      data: [
        // {
        //   menuid: 1,
        //   icon: 'el-icon-document',
        //   menuname: '首页',
        //   hasThird: 'N',
        //   url: 'statistics/DataOverview',
        //   menus: []
        // },
        {
          menuid: 10,
          icon: 'el-icon-document',
          menuname: '文章管理',
          hasThird: null,
          url: null,
          menus: [
            {
              menuid: 12,
              icon: 'el-icon-set-up',
              menuname: '文章列表',
              hasThird: 'N',
              url: 'article/ArticleList',
              menus: null
            },
            {
              menuid: 13,
              icon: 'el-icon-menu',
              menuname: '文章类目',
              hasThird: 'N',
              url: 'category/CategoryList',
              menus: null
            }
          ]
        },
        {
          menuid: 20,
          icon: 'el-icon-user',
          menuname: '用户管理',
          hasThird: null,
          url: null,
          menus: [
            {
              menuid: 21,
              icon: 'el-icon-set-up',
              menuname: '用户列表',
              hasThird: 'N',
              url: 'user/UserList',
              menus: null
            }
          ]
        }, {
          menuid: 30,
          icon: 'el-icon-s-order',
          menuname: '订单管理',
          hasThird: null,
          url: null,
          menus: [
            {
              menuid: 31,
              icon: 'el-icon-set-up',
              menuname: '订单列表',
              hasThird: 'N',
              url: 'order/OrderList',
              menus: null
            }
          ]
        },
        {
          menuid: 40,
          icon: 'el-icon-setting',
          menuname: '系统设置',
          hasThird: null,
          url: null,
          menus: [
            {
              menuid: 41,
              icon: 'el-icon-picture-outline',
              menuname: '轮播图设置',
              hasThird: 'N',
              url: 'carousel/CarouselList',
              menus: null
            },
            {
              menuid: 42,
              icon: 'el-icon-folder-opened',
              menuname: '渠道设置',
              hasThird: 'N',
              url: 'channel/ChannelList',
              menus: null
            }, {
              menuid: 43,
              icon: 'el-icon-medal',
              menuname: '积分设置',
              hasThird: 'N',
              url: 'credits/CreditsConfig',
              menus: null
            }, {
              menuid: 44,
              icon: 'el-icon-medal-1',
              menuname: '等级设置',
              hasThird: 'N',
              url: 'level/Level',
              menus: null
            }, {
              menuid: 45,
              icon: 'el-icon-present',
              menuname: '礼物设置',
              hasThird: 'N',
              url: 'gift/GiftList',
              menus: null
            },
            {
              menuid: 46,
              icon: 'el-icon-s-home',
              menuname: '官网设置',
              hasThird: 'N',
              url: 'home/home',
              menus: null
            },
            {
              menuid: 47,
              icon: 'el-icon-notebook-2',
              menuname: '用户隐私协议设置',
              hasThird: 'N',
              url: 'privacy/privacy',
              menus: null
            },
            {
              menuid: 48,
              icon: 'el-icon-video-play',
              menuname: '视频号设置',
              hasThird: 'N',
              url: 'vedio/vedio',
              menus: null
            }

          ]
        },
        {
          menuid: 50,
          icon: 'el-icon-s-data',
          menuname: '数据中心',
          hasThird: null,
          url: null,
          menus: [
            // {
            //   menuid: 51,
            //   icon: 'el-icon-s-data',
            //   menuname: '每日数据统计',
            //   hasThird: 'N',
            //   url: 'statistics/DailyStatistics',
            //   menus: null
            // },
            // {
            //   menuid: 52,
            //   icon: 'el-icon-s-data',
            //   menuname: '文章数据统计',
            //   hasThird: 'N',
            //   url: 'statistics/ArticleDailyStatistics',
            //   menus: null
            // },
            // {
            //   menuid: 53,
            //   icon: 'el-icon-s-data',
            //   menuname: '渠道数据统计',
            //   hasThird: 'N',
            //   url: 'statistics/ChannelDailyStatistics',
            //   menus: null
            // },
            {
              menuid: 54,
              icon: 'el-icon-s-data',
              menuname: '数据统计',
              hasThird: 'N',
              url: 'statistics/DataStatistics',
              menus: null
            },
            {
              menuid: 55,
              icon: 'el-icon-s-data',
              menuname: '附件下载数据统计',
              hasThird: 'N',
              url: 'statistics/DownloadStatistics',
              menus: null
            },
            {
              menuid: 56,
              icon: 'el-icon-s-data',
              menuname: '文章浏览数据统计',
              hasThird: 'N',
              url: 'statistics/ArticleStatistics',
              menus: null
            },
            {
              menuid: 57,
              icon: 'el-icon-s-data',
              menuname: '数据总览-访客',
              hasThird: 'N',
              url: 'statistics/VisitorStatisticsList',
              menus: null
            }
          ]
        },
        {
          menuid: 60,
          icon: 'el-icon-s-data',
          menuname: '数据记录',
          hasThird: null,
          url: null,
          menus: [
            {
              menuid: 61,
              icon: 'el-icon-s-data',
              menuname: '附件下载记录',
              hasThird: 'N',
              url: 'record/DownloadRecordList',
              menus: null
            },
            {
              menuid: 62,
              icon: 'el-icon-s-data',
              menuname: '表单提交记录',
              hasThird: 'N',
              url: 'record/FormSubmitRecordList',
              menus: null
            }, {
              menuid: 63,
              icon: 'el-icon-s-data',
              menuname: '文章浏览记录',
              hasThird: 'N',
              url: 'record/ArticleBrowsingRecordList',
              menus: null
            }, {
              menuid: 64,
              icon: 'el-icon-s-data',
              menuname: '文章转发记录',
              hasThird: 'N',
              url: 'record/ArticleShareRecordList',
              menus: null
            }, {
              menuid: 65,
              icon: 'el-icon-s-data',
              menuname: '访客记录',
              hasThird: 'N',
              url: 'record/VisitorRecordList',
              menus: null
            }
          ]
        }
      ],
      msg: 'success'
    }
    this.allmenu = res.data

    // menu(localStorage.getItem('logintoken'))
    //   .then(res => {
    //     console.log(JSON.stringify(res))
    //     if (res.success) {
    //       this.allmenu = res.data
    //     } else {
    //       this.$message.error(res.msg)
    //       return false
    //     }
    //   })
    //   .catch(err => {
    //     this.$message.error('菜单加载失败，请稍后再试！')
    //   })
    // 监听
    // this.$root.Bus.$on('toggle', value => {
    //   this.collapsed = !value
    // })
  }
}
</script>
<style>
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 240px;
  min-height: 400px;
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  border: none;
  text-align: left;
}



/* 针对当前激活的菜单栏 */
.el-menu-item {
  /* background-color: #23313b !important; */
  background-color: #23313b;
}

.home-gbc {
  background-color: #051f24 !important;
}

/**激活的菜单项 */
.el-menu-item.is-active {
  background-color: #45535b !important;
  position: relative;
  /* 确保伪元素相对于这个元素定位 */
}

.el-menu-item.is-active::before {
  content: '';
  /* 伪元素需要 content 属性，即使它是空的 */
  position: absolute;
  top: 0;
  /* 根据需要调整位置 */
  left: 0;
  /* 放置在左侧 */
  height: 100%;
  /* 与元素等高 */
  width: 5px;
  /* 竖线的宽度 */
  background-color: #518a99;
  /* 竖线的颜色 */
}

.el-menu-item-group__title {
  padding: 0px;
}

.el-menu-bg {
  background-color: #1f2d3d !important;
}

.el-menu {
  border: none;
}

.logobox {
  height: 40px;
  line-height: 40px;
  color: #9d9d9d;
  font-size: 20px;
  text-align: center;
  padding: 20px 0px;
}

.logoimg {
  height: 40px;
}
</style>