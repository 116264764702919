import { loginreq, req } from './axiosFun';

// 登录接口 
export const login = (params) => { return req("post", "/systemUser/login", params) };
// 获取用户菜单
// export const menu = (params) => { return axios.get("/api/menu?&token=" + localStorage.getItem('logintoken')).then(res => res.data) };
// // 退出接口
// export const loginout = () => { return axios.delete("/api/login?&token=" + localStorage.getItem('logintoken')).then(res => res.data) };

/**
 * 用户管理 
 **/
// 用户管理-获取用户列表
export const getUserList = (params) => {
    return req("get", "/user/get_by_condition?page=" + params.page
        + "&pageSize=" + params.pageSize
        + "&username=" + params.username
        + "&phoneNumber=" + params.phoneNumber
        + "&openid=" + params.openid
        + "&type=" + params.type, params)
};

export const changeUserIsEnableReq = (params) => { return req("put", "/user/change_enable", params) };

export const editUserMemoReq = (params) => { return req("put", "/user/edit_memo", params) };

// // 权限管理-获取权限列表
// export const permissionList = (params) => { return req("post", "/api/Permission/list", params) };
// // 权限管理-保存权限
// export const ermissionSave = (params) => { return req("post", "/api/Permission/save", params) };
// // 权限管理-删除权限
// export const ermissionDelete = (params) => { return axios.delete("/api/Permission/delete?ids=" + params + "&token=" + localStorage.getItem('logintoken')).then(res => res.data) };
// // 权限管理-获取权限
// export const roleDropDown = () => { return axios.get("/api/Role/dropDown/all?&token=" + localStorage.getItem('logintoken')).then(res => res.data) };
// // 权限管理-配置权限
// export const RolePermission = (params) => { return req("post", "/api/RolePermission/save", params) };