import { req } from './axiosFun';


export const getStatisticalByCondition = (params) => { return req("get", "/statistical/get_statistical?page=" + params.page + "&startDate=" + params.startDate + "&endDate=" + params.endDate, params) };
export const getArticleStatisticalByCondition = (params) => { return req("get", "/statistical/get_article_statistical?page=" + params.page + "&startDate=" + params.startDate + "&endDate=" + params.endDate + "&articleTitle=" + params.articleTitle + "&articleId=" + params.articleId, params) };
export const getChannelStatisticalByCondition = (params) => { return req("get", "/statistical/get_channel_statistical?page=" + params.page + "&startDate=" + params.startDate + "&endDate=" + params.endDate + "&channel=" + params.channel + "&channelId=" + params.channelId, params) };
export const getDateOverview = (params) => { return req("get", "/records/get_data_overview?startDate=" + params.startDate + "&endDate=" + params.endDate, params) };
export const getDownloadRecordReq = (params) => {
    return req("get", "/records/get_download_record_by_condition?startDate=" +
        params.startDate +
        "&endDate=" + params.endDate +
        "&channelId=" + params.channelId +
        "&id=" + params.id +
        "&page=" + params.page +
        "&pageSize=" + params.pageSize, params)
};
export const getFormSubmitRecordReq = (params) => {
    return req("get", "/records/get_submit_record_by_condition?startDate=" +
        params.startDate +
        "&endDate=" + params.endDate +
        "&channelId=" + params.channelId +
        "&id=" + params.id +
        "&page=" + params.page +
        "&pageSize=" + params.pageSize, params)
};
export const getArticleBrowsingRecordReq = (params) => {
    return req("get", "/records/get_article_record_by_condition?startDate=" +
        params.startDate +
        "&endDate=" + params.endDate +
        "&channelId=" + params.channelId +
        "&id=" + params.id +
        "&page=" + params.page +
        "&pageSize=" + params.pageSize, params)
};
export const getVisitorRecordReq = (params) => {
    return req("get", "/records/get_visitor_by_condition?startDate=" +
        params.startDate +
        "&endDate=" + params.endDate +
        "&channelId=" + params.channelId +
        "&id=" + params.id +
        "&page=" + params.page +
        "&username=" + params.username +
        "&pageSize=" + params.pageSize, params)
};
export const getStatisticalChannelRecordReq = (params) => {
    return req("get", "/statistical/get_channel_statistical?startDate=" +
        params.startDate +
        "&endDate=" + params.endDate +
        "&channelId=" + params.channelId +
        "&id=" + params.id +
        "&page=" + params.page +
        "&pageSize=" + params.pageSize, params)
};
export const getDownloadStatisticalReq = (params) => {
    return req("get", "/statistical/get_download_statistical?startDate=" +
        params.startDate +
        "&endDate=" + params.endDate +
        "&channelId=" + params.channelId +
        "&id=" + params.id +
        "&page=" + params.page +
        "&pageSize=" + params.pageSize, params)
};
export const getArticleStatisticalReq = (params) => {
    return req("get", "/statistical/get_article_statistical?startDate=" +
        params.startDate +
        "&endDate=" + params.endDate +
        "&channelId=" + params.channelId +
        "&id=" + params.id +
        "&page=" + params.page +
        "&pageSize=" + params.pageSize, params)
};
export const getReadingRecordByOpenidReq = (params) => {
    return req("get", "/records/get_article_browsing_by_userid?startDate=" +
        params.startDate +
        "&endDate=" + params.endDate +
        "&channelId=" + params.channelId +
        "&userId=" + params.id +
        "&page=" + params.page +
        "&pageSize=" + params.pageSize, params)
};

export const getDownloadRecordByUserIdReq = (params) => {
    return req("get", "/records/get_download_record_by_userid?startDate=" +
        params.startDate +
        "&endDate=" + params.endDate +
        "&channelId=" + params.channelId +
        "&id=" + params.id +
        "&page=" + params.page +
        "&pageSize=" + params.pageSize, params)
};
export const getShareRecordByUserIdReq = (params) => {
    return req("get", "/records/get_share_record_by_userid?startDate=" +
        params.startDate +
        "&endDate=" + params.endDate +
        "&channelId=" + params.channelId +
        "&id=" + params.id +
        "&page=" + params.page +
        "&pageSize=" + params.pageSize, params)
};

export const getCreditDetailByUserIdReq = (params) => {
    return req("get", "/records/get_credit_detail_by_userid?id=" + params.id +
        "&page=" + params.page +
        "&pageSize=" + params.pageSize, params)
};

export const getOrderListByUserIdReq = (params) => {
    return req("get", "/order/get_order_by_userid?id=" + params.id +
        "&giftTitle=" + params.giftTitle +
        "&endDate=" + params.endDate +
        "&startDate=" + params.startDate +
        "&orderState=" + params.orderState +
        "&page=" + params.page +
        "&pageSize=" + params.pageSize, params)
};

export const getShareRecordByReq = (params) => {
    return req("get", "/records/get_share_by_condition?startDate=" +
        params.startDate +
        "&endDate=" + params.endDate +
        "&page=" + params.page +
        "&pageSize=" + params.pageSize, params)
};

export const getVisitorRecordByConditionReq = (params) => {
    return req("get", "/records/get_visitor_record_by_condition?username=" + params.username +
        "&endDate=" + params.endDate +
        "&startDate=" + params.startDate +
        "&isRegister=" + params.isRegister +
        "&phoneNumber=" + params.phoneNumber +
        "&page=" + params.page +
        "&pageSize=" + params.pageSize, params)
};