<template>
  <div>

    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right" style="width: 60%;">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>礼物列表</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 搜索筛选 -->
    <el-form :inline="true" :model="formInline" class="user-search" style="width: 100%;">
      <el-form-item label="搜索">
        <el-input size="small" v-model="formInline.title" placeholder="输入礼物名称" clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-date-picker v-model="formInline.date" @change="dateChange" type="daterange" range-separator="至" size="small"
          start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd HH:mm:ss"
          :default-time="['00:00:00', '23:59:59']">
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button size="small" type="primary" icon="el-icon-search" @click="search">搜索</el-button>
        <el-button size="small" type="info" icon="el-icon-refresh-right" @click="reserFormInLine">重置</el-button>
        <el-button size="small" type="primary" icon="el-icon-plus" @click="handleEdit()">添加</el-button>
      </el-form-item>
    </el-form>
    <!--列表-->
    <el-table size="small" :data="listData" highlight-current-row v-loading="loading" border
      element-loading-text="拼命加载中" style="width: 80%;">
      <el-table-column align="center" type="index" label="序号" min-width="100">
      </el-table-column>
      <el-table-column align="center" prop="images" label="图片" min-width="80" show-overflow-tooltip>
        <template slot-scope="scope">
          <img :src="scope.row.images[0]" style="width: 50px; height: 50px;">
        </template>
      </el-table-column>
      <el-table-column align="center" prop="title" label="礼物标题" min-width="60" show-overflow-tooltip>
      </el-table-column>
      <el-table-column align="center" prop="credits" label="所需积分" min-width="60" show-overflow-tooltip>
      </el-table-column>
      <el-table-column align="center" prop="enable" label="状态" min-width="60" show-overflow-tooltip
        :filters="enableFilters" :filter-method="filterHandler">
        <template slot-scope="scope">
          <el-switch v-model="scope.row.enable" @change="switchChange(scope.row)" active-color="#13ce66"
            inactive-color="#ff4949">
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="createTime" label="创建时间" mi-width="100" show-overflow-tooltip>
        <template slot-scope="scope">
          <div>{{ scope.row.createTime | timestampToDate }}</div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" width="250">
        <template slot-scope="scope">
          <el-button size="mini" type="primary" @click="handleShow(scope.$index, scope.row)">查看</el-button>
          <el-button size="mini" type="warning" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
          <el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页组件 -->
    <el-pagination @current-change="handleCurrentChange" :hide-on-single-page="true"
      :current-page.sync="formInline.page" :page-count="formInline.totalPage" layout="prev, pager, next, jumper"
      :total="formInline.total">
    </el-pagination>
    <!-- 编辑界面 -->
    <el-dialog :title="title" :visible.sync="editFormVisible" width="450px" @click="closeDialog('editForm')"
      :close-on-click-modal="false">
      <div class="avatar-container">{{ showGift ? '礼物图片' : '上传图片' }}&nbsp;&nbsp;&nbsp;&nbsp;
        <img v-for="(item, index) in editForm.images" :key="index" :src="item" class="avatar m-r-10"
          @click="deleteImage(editForm.images, index)">
        <el-upload v-if="editForm.images.length < 3 && !showGift" class="avatar-uploader avatar-uploader-border"
          :action="uploadImagePath" :show-file-list="false" :headers="headers" :on-success="handleAvatarSuccess"
          :before-upload="beforeAvatarUpload">

          <i class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>

      </div>
      <h5 style="margin-left: 50px;" v-if="!showGift">*点击图片删除，最多上传3张图片</h5>


      <el-form label-width="120px" :model="editForm" :rules="rules" ref="editForm">
        <el-form-item label="礼物标题" prop="title">
          <el-input style="width:90%;" size="small" :disabled="showGift" v-model="editForm.title" auto-complete="off"
            placeholder="请输入兑换积分"></el-input>
        </el-form-item>
        <el-form-item label="兑换积分" prop="credits">
          <el-input size="small" v-model="editForm.credits" :disabled="showGift" auto-complete="off"
            placeholder="请输入兑换积分" style="width:90%;"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="closeDialog('editForm')">取消</el-button>
        <el-button v-if="!showGift" size="small" type="primary" :loading="loading" class="title"
          @click="submitForm('editForm')">保存</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import Editor from '../../components/Editor'
import { addGift, editGift, deleteGift, getGiftByCondition, configCategory, changeGiftEnableReq } from '../../api/configMG'
import { getUploadImagePath } from '../../utils/util'
import router from '../../router';

export default {
  data() {
    return {
      editorRef: 'test',
      editorContent: '',
      loading: false, //是显示加载
      editFormVisible: false, //控制编辑页面显示与隐藏
      configFormVisible: false,//配置页面显示与隐藏
      showGift: false,
      title: '添加',
      uploadImagePath: '',
      editForm: {
        id: null,
        credits: null,
        title: '',
        images: [],
        image: null
      },
      // rules表单验证
      rules: {
        title: [{ required: true, message: '请输入礼物标题', trigger: 'blur' }],
        credits: [{ required: true, message: '请输入兑换积分', trigger: 'blur' }],
      },
      formInline: {
        page: 1,
        totalPage: 1,
        total: 0,
        title: '',
        date: [],
        startDate: '',
        endDate: '',
        pageSize: 10
      },
      enableFilters: [{ text: '上架', value: true }, { text: '下架', value: false }],
      listData: [], //类目数据
      configList: []//某个类目的配置数组

    }
  },
  components: { Editor },
  computed: {
    headers() {
      return {
        // 'Content-Type': 'multipart/form-data',
        'Authorization': localStorage.getItem('refreshToken')
      }
    }
  },

  /**
   * 数据发生改变
   */

  /**
   * 创建完毕
   */
  created() {

  },
  mounted() {
    this.uploadImagePath = getUploadImagePath()
    this.getdata()
  },
  /**
   * 里面的方法只有被调用才会执行
   */
  methods: {
    // 获取公司列表
    async getdata(parameter) {
      //this.loading = true
      let res = await getGiftByCondition(this.formInline)
      if (res.data && res.code === 200) {
        for (let item of res.data.records) {
          item.images = item.image.split(',')
          item.enable = item.isEnable === 1 ? true : false
        }
        this.listData = res.data.records

        // this.formInline.totalPage = res.data.totalPage
        this.formInline.total = res.data.total
      }
    },
    //日期选择器中日期变化时触发
    dateChange() {
      if (this.formInline.date) {
        this.formInline.startDate = this.formInline.date[0]
        this.formInline.endDate = this.formInline.date[1]
      } else {
        this.formInline.startDate = ''
        this.formInline.endDate = ''
      }

    },

    handleCurrentChange() {
      this.getdata()
    },
    // 搜索事件
    async search() {
      this.getdata(this.formInline)
    },
    //显示编辑界面
    handleEdit: function (index, row) {
      this.editFormVisible = true
      this.showGift = false
      if (row != undefined && row != 'undefined') {
        this.title = '修改'
        this.editForm.id = row.id
        this.editForm.title = row.title
        this.editForm.images = row.images
        this.editForm.credits = row.credits
      } else {
        this.title = '添加'
        this.editForm.id = null
        this.editForm.categoryName = ''
        this.editForm.image = null
      }
    },

    handleShow(index, row) {
      this.title = '查看'
      this.showGift = true
      this.editFormVisible = true
      this.editForm.id = row.id
      this.editForm.title = row.title
      this.editForm.images = row.images
      this.editForm.credits = row.credits
    },
    // 编辑、增加页面保存方法
    submitForm(editData) {
      if (this.editForm.images.length < 1) {
        this.$message({
          type: 'warning',
          message: '请上传礼物图片'
        });
        return
      }
      this.$refs[editData].validate(async valid => {
        if (valid) {
          if (!this.editForm.id) {
            this.editForm.id = ""
            this.editForm.image = this.editForm.images.join(',')
            let res = await addGift(this.editForm)
            if (res.code === 200) {
              this.$message({
                type: 'success',
                message: '添加成功！'
              })
              this.resetEditForm()
              this.formInline.page = 1
              this.getdata(this.formInline)
            } else {
              this.$message({
                type: 'error',
                message: res.message
              });
            }
          } else {
            this.editForm.image = this.editForm.images.join(',')
            let res = await editGift(this.editForm)
            if (res.code === 200) {
              this.$message({
                type: 'success',
                message: '修改成功！'
              })
              this.resetEditForm()
              this.getdata(this.formInline)
            } else {
              this.$message({
                type: 'error',
                message: res.message
              });
            }
          }
          this.editFormVisible = false
          this.$refs[editData].resetFields()
        } else {
          return false
        }
      })
    },// 编辑、增加页面保存方法
    async submitConfigForm() {
      let params = {}
      for (let item of this.configList) {
        for (let key in item) {
          if (key != 'name' && key != 'isNecessary' && key != 'isEnable') {
            params[key] = item.isNecessary ? 2 : item.isEnable ? 1 : 0
          }
        }
      }
      params.categoryId = this.editForm.id
      let res = await configCategory(params)
      if (res.code === 200) {
        this.$message({
          type: 'success',
          message: '配置成功！'
        });
        this.getdata()
      } else {
        this.$message({ type: 'error', message: res.message });
      }
      this.configList = []
      this.editForm.id = null
      this.configFormVisible = false
      this.showcatelog = false
      this.resetEditForm()
    },
    // 删除文章类目
    handleDelete(index, row) {
      this.$confirm('确定要删除吗?', '信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          let res = await deleteGift({ id: row.id })
          if (res.code === 200) {
            this.$message({
              type: 'success',
              message: '修改成功！'
            })
            this.formInline.page = 1
            this.getdata(this.formInline)
          } else {
            this.$message({
              type: 'error',
              message: res.message
            });
          }

        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    // 关闭编辑、增加弹出框
    closeDialog(formName) {
      this.editFormVisible = false
      this.showGift = false
      // this.$refs[formName].resetFields()
      this.resetEditForm()
    },
    closeConfigDialog(formName) {
      this.configFormVisible = false
      this.showcatelog = false
      // this.$refs[formName].resetFields()
      this.resetEditForm()
    },
    beforeAvatarUpload() {

    },
    handleAvatarSuccess(response) {
      if (response.code === 200) {
        this.editForm.images.push(response.data)
        this.$message({
          type: 'success',
          message: '图片上传成功！'
        })
      } else {
        this.$message({ type: 'error', message: response.message });
        //定时2秒
        setTimeout(() => {
          router.push({ path: '/login' })
        }, 1000);
      }
    },
    resetEditForm() {
      this.editForm.id = ''
      this.editForm.title = ''
      this.editForm.image = null
      this.editForm.images = []
      this.editForm.credits = ''
    },
    filterHandler(value, row, column) {
      return row.enable === value;
    },
    async switchChange(row) {
      let res = await changeGiftEnableReq({ giftId: row.id, isEnable: row.enable ? 1 : 0 })
      if (res.code === 200) {
        this.$message({
          type: 'success',
          message: row.enable ? '上架成功！' : '下架成功！'
        })
        this.getdata()
      } else {
        this.$message({
          type: 'error',
          message: res.message
        });
      }
    },
    deleteImage(images, index) {
      if (this.showGift) {
        return
      }
      this.$confirm('确定删除图片?', '信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          images.splice(index, 1)
        })
        .catch(() => {

        })

    },
    reserFormInLine() {
      this.formInline = {
        page: 1,
        totalPage: 1,
        total: 0,
        title: '',
        date: [],
        startDate: '',
        endDate: '',
        pageSize: 10
      }
    }
  }
}
</script>

<style scoped>
.user-search {
  margin-top: 20px;
}

.userRole {
  width: 100%;
}

.avatar-uploader {
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  width: 80px;
  height: 80px;
  overflow: hidden;
}

.avatar-uploader-border {
  border: 1px dashed #8c939d;
}

.avatar-uploader:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
}

.avatar-container {
  display: flex;
  line-height: 80px;
  margin-left: 49px;
  margin-bottom: 10px;
}

.avatar {
  width: 80px;
  height: 80px;
  display: block;
}

.m-r-10 {
  margin-right: 10px;
}
</style>