<template>
  <div>

    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right" style="width: 60%;margin-bottom: 30px;">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>官网设置</el-breadcrumb-item>
    </el-breadcrumb>

    <el-table size="small" :data="listData" highlight-current-row v-loading="loading" border
      element-loading-text="拼命加载中" style="width: 40%;">
      <el-table-column align="center" type="index" label="序号" min-width="10">
      </el-table-column>

      <el-table-column align="center" prop="homeUrl" label="官网地址" mi-width="200" show-overflow-tooltip>

      </el-table-column>
      <el-table-column align="center" label="操作" min-width="40">
        <template slot-scope="scope">
          <el-button size="mini" type="warning" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
          <!-- <el-button size="mini" type="danger" @click="deleteUser(scope.$index, scope.row)">删除</el-button> -->
        </template>
      </el-table-column>
    </el-table>
    <!-- 编辑界面 -->
    <el-dialog :title="title" :visible.sync="editFormVisible" width="400px" @click="closeDialog('editForm')"
      :close-on-click-modal="false">
      <el-form label-width="100px" :model="editForm" :rules="rules" ref="editForm">
        <el-form-item label="官网地址" prop="homeUrl">
          <el-input size="small" v-model="editForm.homeUrl" auto-complete="off" placeholder="请输入官网地址"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="closeDialog()">取消</el-button>
        <el-button size="small" type="primary" :loading="loading" class="title"
          @click="submitForm('editForm')">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getHomeUrlReq, editHomeUrlReq } from '../../api/configMG'
export default {
  data() {
    return {
      loading: false, //是显示加载
      editFormVisible: false, //控制编辑页面显示与隐藏
      title: '添加',

      editForm: {
        id: null,
        homeUrl: ''
      },
      // rules表单验证
      rules: {
        homeUrl: [{ required: true, message: '请输入官网地址', trigger: 'blur' }],

      },
      formInline: {
        page: 1,
        totalPage: 1,
        total: 0,
        channelName: '',
        date: [],
        startDate: '',
        endDate: '',
      },
      listData: [], //用户数据
      // 分页参数
      pageparm: {
        currentPage: 1,
        pageSize: 10,
        total: 10
      }
    }
  },

  /**
   * 数据发生改变
   */

  /**
   * 创建完毕
   */
  created() {

  },
  mounted() {
    this.getdata()
  },
  /**
   * 里面的方法只有被调用才会执行
   */
  methods: {
    // 获取公司列表
    async getdata(parameter) {
      //this.loading = true
      this.listData = []
      let res = await getHomeUrlReq()
      if (res.data && res.code === 200) {
        this.listData.push({ homeUrl: res.data })
        this.editForm.homeUrl = res.data
      }
    },
    //日期选择器中日期变化时触发
    dateChange() {
      this.formInline.startDate = this.formInline.date[0]
      this.formInline.endDate = this.formInline.date[1]
    },

    handleCurrentChange() {
      this.getdata()
    },
    // 搜索事件
    async search() {
      this.getdata(this.formInline)
    },
    //显示编辑界面
    handleEdit: function (index, row) {
      this.editFormVisible = true
      if (row != undefined && row != 'undefined') {
        this.title = row.info
        this.editForm.id = row.id
        if (row.id === 2) {
          this.editForm.articleRuleStart = row.article
          this.editForm.downloadRuleStart = row.download
        } else {
          this.editForm.articleRuleStart = row.articleRuleStart
          this.editForm.articleRuleEnd = row.articleRuleEnd
          this.editForm.downloadRuleStart = row.downloadRuleStart
          this.editForm.downloadRuleEnd = row.downloadRuleEnd
        }

        this.editForm.info = row.info
      }
    },
    // 编辑、增加页面保存方法
    async submitForm(editData) {
      this.$refs[editData].validate(async valid => {
        if (valid) {
          let res = await editHomeUrlReq({ homeUrl: this.editForm.homeUrl })
          if (res.code === 200) {
            this.$message.success("保存成功")
            this.getdata()
          }
          this.editFormVisible = false
        } else {
          return false
        }
      })
    },

    // 关闭编辑、增加弹出框
    closeDialog() {
      this.editFormVisible = false
      this.editForm.id = ''
      this.editForm.articleRule.length = 0
      this.editForm.downloadRule.length = 0
    }
  }
}
</script>

<style scoped>
.user-search {
  margin-top: 20px;
}

.userRole {
  width: 100%;
}
</style>