<template>
  <div>

    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right" style="width: 60%;">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>文章列表</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 搜索筛选 -->
    <el-form :inline="true" :model="formInline" class="user-search" style="width: 100%;">
      <el-form-item label="搜索">
        <el-input size="small" v-model="formInline.title" placeholder="输入文章标题" clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-select v-model="formInline.categoryId" placeholder="请选择文章类目" size="small" clearable>
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-date-picker v-model="formInline.date" @change="dateChange" type="daterange" range-separator="至"
          start-placeholder="开始日期" end-placeholder="结束日期" size="small" value-format="yyyy-MM-dd HH:mm:ss"
          :default-time="['00:00:00', '23:59:59']">
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button size="small" type="primary" icon="el-icon-search" @click="search">搜索</el-button>
        <el-button size="small" type="info" icon="el-icon-refresh-right" @click="reserFormInLine">重置</el-button>
        <el-button size="small" type="primary" icon="el-icon-plus"
          @click="handleEdit(null, null, 'editForm')">添加</el-button>
      </el-form-item>
    </el-form>
    <!--列表-->
    <el-table size="small" :data="listData" highlight-current-row v-loading="loading" border
      element-loading-text="拼命加载中" style="width: 95%;">
      <el-table-column align="center" type="index" label="序号" min-width="100">
      </el-table-column>
      <!-- <el-table-column align="center" prop="image" label="图片"  min-width="50" show-overflow-tooltip>
        <template slot-scope="scope">
          <img :src="scope.row.image" style="width: 50px; height: 50px;">
        </template>
</el-table-column> -->
      <el-table-column align="center" prop="title" label="文章标题" min-width="120" show-overflow-tooltip>
      </el-table-column>
      <el-table-column align="center" prop="categoryName" label="所属类目" min-width="60" show-overflow-tooltip>
      </el-table-column>
      <el-table-column align="center" prop="enable" label="是否上架" min-width="60" show-overflow-tooltip
        :filters="enableFilters" :filter-method="filterHandler">
        <template slot-scope="scope">
          <el-switch v-model="scope.row.enable" @change="switchChange(scope.row)" :disabled="showcatelog"
            active-color="#13ce66" inactive-color="#ff4949">
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="recommend" label="设为推荐文章" min-width="65" show-overflow-tooltip
        :filters="recommendFilters" :filter-method="recommendFilterHandler">
        <template slot-scope="scope">
          <el-image :src="require(scope.row.recommend ? '@/assets/img/recommend.svg' : '@/assets/img/unrecommend.svg')"
            style="width: 25px; height: 25px;" @click="switchRecommendChange(scope.row)"></el-image>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="weight" label="文章权重" min-width="55" show-overflow-tooltip>
        <template slot-scope="scope">
          <el-select v-model="scope.row.weight" placeholder="请选择" size="mini" @change="weightChange(scope.row)"
            style="width:60px">
            <el-option v-for="item in articleWeightOptions" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="createTime" label="创建时间" min-width="50" show-overflow-tooltip>
        <template slot-scope="scope">
          <div>{{ scope.row.createTime | timestampToDate }}</div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" width="380px">
        <template slot-scope="scope">
          <el-button size="mini" type="primary" @click="handleShow(scope.$index, scope.row)">查看</el-button>
          <el-button size="mini" type="success" @click="handleConfig(scope.$index, scope.row)">附件</el-button>
          <el-button size="mini" type="warning" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
          <el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
          <el-button size="mini" type="info" @click="showLinkDialog(scope.$index, scope.row)">链接</el-button>

        </template>
      </el-table-column>
    </el-table>
    <!-- 分页组件 -->
    <el-pagination @current-change="handleCurrentChange" :hide-on-single-page="true"
      :current-page.sync="formInline.page" :page-count="formInline.totalPage" layout="prev, pager, next, jumper"
      :total="formInline.total">
    </el-pagination>
    <!-- 编辑界面 -->
    <el-dialog :title="title" :visible.sync="editFormVisible" width="730px" :close-on-click-modal="false"
      :destroy-on-close="true">
      <div class="avatar-container" style="margin-top: -20px;">上传图片&nbsp;&nbsp;&nbsp;&nbsp;
        <img v-for="(item, index) in editForm.imageList" :key="index" :src="item" class="avatar m-r-10"
          @click="deleteImage(editForm.imageList, index)">
        <el-upload class="avatar-uploader avatar-uploader-border" :action="uploadImagePath" :show-file-list="false"
          :headers="headers" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">

          <i class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </div>
      <el-form label-width="120px" :model="editForm" :rules="rules" ref="editForm">
        <div style="display: flex;">
          <el-form-item label="文章标题" prop="title" style="margin-bottom: 10px;">
            <el-input size="small" v-model="editForm.title" auto-complete="off" placeholder="请输入文章标题"
              style="width:205px"></el-input>
          </el-form-item>
          <el-form-item label="文章类目" prop="categoryId">
            <el-select v-model="editForm.categoryId" placeholder="请选择文章类目" size="small">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </div>
      </el-form>
      <Editor v-if="editFormVisible" v-model="editForm.contents" :contents="editForm.contents" :editorRef="editorRef"
        @getContent="getEditorContent" class="m-l-48 h-340 m-r-40 m-t-10"></Editor>
      <div slot="footer" class="dialog-footer m-r-40 " style="margin-top: 60px;">
        <el-button size="small" @click="closeDialog('editForm')">取消</el-button>
        <el-button size="small" type="primary" :loading="loading" class="title"
          @click="submitForm('editForm')">保存</el-button>
      </div>
    </el-dialog>

    <!-- 查看界面 -->
    <el-dialog :title="title" :visible.sync="showFormVisible" width="730px" @click="closeDialog('editForm')"
      :close-on-click-modal="false">
      <div class="avatar-container" style="margin-top: -20px;">文章图片&nbsp;&nbsp;&nbsp;&nbsp;
        <img v-for="(item, index) in editForm.imageList" :key="index" :src="item" class="avatar m-r-10">

      </div>
      <el-form label-width="120px" :model="editForm">
        <div style="display: flex;">
          <!-- <el-form-item label="文章标题" prop="title" style="margin-bottom: 10px;"> -->
          <!-- <el-input size="small" v-model="editForm.title" auto-complete="off" placeholder="请输入文章标题" disabled="true"
              style="width:205px"></el-input> -->
          <div class="m-l-48 l-h-30  m-r-10">文章标题</div>
          <div class="form-label" style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
            {{
              editForm.title }}
          </div>
          <!-- </el-form-item> -->
          <div class="m-l-38 l-h-30  m-r-10">文章类目</div>

          <div class="form-label">
            {{
              editForm.categoryName }}
          </div>
        </div>
      </el-form>
      <Editor v-if="showFormVisible" :disabled="true" v-model="editForm.contents" :contents="editForm.contents"
        :editorRef="editorRef" class="m-l-48 h-340 m-r-40 m-t-10"></Editor>


      <div slot="footer" class="dialog-footer m-r-40 " style="margin-top: 60px;">
        <div style="left: 70px;position: absolute;">附件：<el-link type="primary" :href="editForm.resourcePath">{{
          editForm.resourceName ? editForm.resourceName : '' }}</el-link></div>
        <el-button size="small" @click="closeShowDialog('editForm')">关闭</el-button>

      </div>
    </el-dialog>

    <!-- 上传附件 -->
    <el-dialog :title="title" :visible.sync="showUploadVisible" width="500px" @click="closeDialog('editForm')"
      :close-on-click-modal="false">

      <el-upload class="upload-demo" :action="uploadFilePath" multiple :limit="3" :on-success="handleFileSuccess"
        :headers="headers">
        <el-button size="small" type="primary">选择文件</el-button>
        <div slot="tip" class="el-upload__tip">点击选择文件，上传文件</div>
      </el-upload>

      <!-- <div slot="footer" class="dialog-footer m-r-40 m-t-40">
        <el-button size="small" @click="closeUploadDialog">取消</el-button>
        <el-button size="small" type="primary" :loading="loading" class="title" @click="uploadSubmit">保存</el-button>
      </div> -->
    </el-dialog>

    <!-- 附件配置弹窗 -->
    <el-dialog :title="title" :visible.sync="showResourceDialog" width="900px" @click="closeDialog('editForm')"
      :close-on-click-modal="false">

      <el-upload class="upload-demo" :action="uploadFilePath" multiple :limit="3" :on-success="handleFileSuccess"
        :on-error="handleFileFail" :headers="headers" :show-file-list="false" :before-upload="beforeFileUpload">
        <el-button size="small" type="primary">添加附件</el-button>

      </el-upload>


      <el-radio-group v-model="resourceRadio" style="margin-top: 20px;" @input="resourceRadioChange">
        <div style="width: 860px;">
          <el-table :data="resourceList" border style="width: 100%">
            <el-table-column align="center" type="index" label="序号" min-width="50">
            </el-table-column>
            <el-table-column align="center" prop="resourceName" label="附件名称" min-width="180">
            </el-table-column>
            <el-table-column align="center" prop="resourcePath" label="附件地址" min-width="180">
            </el-table-column>
            <el-table-column align="center" prop="" label="选择附件" min-width="60">
              <template slot-scope="scope">
                <el-radio :label="scope.row.id"><span></span></el-radio>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="" label="操作" min-width="120">
              <template slot-scope="scope">

                <el-button type="warning" size="mini" @click="renameResource(scope.row)">重命名</el-button>
                <el-button type="danger" size="mini"
                  @click="deleteResourceHandle(scope.row)">删&nbsp;&nbsp;&nbsp;&nbsp;除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-radio-group>
      <div style="margin-top: 5px;">附件大小不能超过20M。小程序端可以直接打开的附件格式： doc, xls, ppt, pdf, docx, xlsx, pptx</div>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="closeDialog('editForm')">关闭</el-button>

      </div>
    </el-dialog>

    <el-dialog title="文章链接" :visible.sync="showLink" width="900px" @click="closeDialog('editForm')"
      :close-on-click-modal="false">

      <div style="width: 860px;">
        <el-table :data="channelList" border style="width: 100%">
          <el-table-column align="center" type="index" label="序号" min-width="50">
          </el-table-column>
          <el-table-column align="center" prop="channelName" label="渠道名" min-width="50">
          </el-table-column>
          <el-table-column align="center" prop="link" label="链接" min-width="180">
          </el-table-column>

          <el-table-column align="center" prop="" label="操作" width="120">
            <template slot-scope="scope">

              <el-button type="primary" size="mini" @click="copyLink(scope.row)">复制</el-button>

            </template>
          </el-table-column>
        </el-table>
      </div>

      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="closeLinkDialog()">关闭</el-button>

      </div>
    </el-dialog>

  </div>
</template>

<script>
import Editor from '../../components/Editor'
import { getCategoryList, addResource, renameResource, deleteResource, getReourceList, configCategory, getChannelListReq } from '../../api/configMG'
import { addArticle, getArticleByCondition, isEnableChange, editArticle, deleteArticle, submitResource, isRecommendChange, weightChange } from '../../api/articleMG'
import { getUploadImagePath, getUploadFilePath, getBaseUrl } from '../../utils/util'
import router from '../../router';
export default {
  data() {
    return {
      editorRef: 'test',
      editorContent: '',
      loading: false, //是显示加载
      editFormVisible: false, //控制编辑页面显示与隐藏
      showFormVisible: false,//配置页面显示与隐藏
      showUploadVisible: false,
      showcatelog: false,
      showLink: false,
      title: '添加',
      uploadImagePath: '',
      uploadFilePath: '',
      fileList: [],
      editForm: {
        id: null,
        categoryName: '',
        categoryId: '',
        title: '',
        images: '',
        imageList: [],
        contents: '',
        content: ''
      },
      // rules表单验证
      rules: {
        categoryId: [{ required: true, message: '请选择文章类目', trigger: 'blur' }],
        title: [{ required: true, message: '请输入文章标题', trigger: 'blur' }],
      },
      formInline: {
        page: 1,
        totalPage: 1,
        total: 0,
        categoryName: '',
        image: null,
        date: [],
        startDate: '',
        endDate: '',
        title: '',
        categoryId: '',
        articleId: ''
      },
      resourceList: [],
      options: [],
      listData: [], //类目数据
      configList: [],//某个类目的配置数组
      enableFilters: [{ text: '上架', value: true }, { text: '下架', value: false }],
      recommendFilters: [{ text: '推荐文章', value: true }],
      articleWeightOptions: [],
      showResourceDialog: false,
      resourceRadio: 1,
      loadingInstance: null,
      channelList: [],
      baseUrl: ''
    }
  },
  components: { Editor },
  computed: {
    headers() {
      return {
        // 'Content-Type': 'multipart/form-data',
        'Authorization': localStorage.getItem('refreshToken')
      }
    }
  },

  /**
   * 数据发生改变
   */

  /**
   * 创建完毕
   */
  created() {
    for (let i = 1; i < 11; i++) {
      this.articleWeightOptions.push({
        value: i,
        label: i
      })
    }
  },
  mounted() {
    this.uploadImagePath = getUploadImagePath()
    this.uploadFilePath = getUploadFilePath()
    this.baseUrl = getBaseUrl()
    this.getCategoryList()
    this.getdata()
  },
  /**
   * 里面的方法只有被调用才会执行
   */
  methods: {
    // 获取公司列表
    async getdata(parameter) {
      //this.loading = true

      let res = await getArticleByCondition(this.formInline)
      if (res.data && res.code === 200) {
        for (let item of res.data.articleList) {
          item.enable = item.isEnable == 1 ? true : false
          item.recommend = item.isRecommend == 1 ? true : false
        }
        this.listData = res.data.articleList
        this.formInline.totalPage = res.data.totalPage
        this.formInline.total = res.data.total
      }

    },
    async getCategoryList(parameter) {
      //this.loading = true
      let res = await getCategoryList()

      if (res.data && res.code === 200) {
        for (let item of res.data) {
          this.options.push({ value: item.id, label: item.categoryName })
        }
      }

    },
    //日期选择器中日期变化时触发
    dateChange() {
      if (this.formInline.date) {
        this.formInline.startDate = this.formInline.date[0]
        this.formInline.endDate = this.formInline.date[1]
      } else {
        this.formInline.startDate = ''
        this.formInline.endDate = ''
      }

    },

    handleCurrentChange() {
      this.getdata()
    },
    // 搜索事件
    async search() {
      this.getdata(this.formInline)
    },
    //显示编辑界面
    handleEdit: function (index, row, formName) {

      if (row != undefined && row != 'undefined') {
        this.title = '修改'
        this.editForm.id = row.id
        this.editForm.categoryName = row.categoryName
        this.editForm.title = row.title
        this.editForm.contents = ''
        this.editForm.contents = row.content
        this.editForm.imageList = row.images.split(',')
        this.editForm.categoryId = row.categoryId
      } else {
        this.title = '添加'
        this.resetEditForm()
      }
      console.log('edit===>', this.editForm.title)
      this.editFormVisible = true
      if (this.$refs[formName]) {
        this.$refs[formName].resetFields()
      }
    },
    async getResourceData() {
      let res = await getReourceList()
      if (res.code === 200) {
        this.resourceList = res.data
        this.resourceRadio = 0
      } else {
        this.$message({
          type: 'error',
          message: res.message
        });
      }
    },
    getEditorContent(content) {
      this.editForm.contents = content
    },
    handleConfig(index, row) {
      this.getResourceData()
      this.showResourceDialog = true
      this.editForm.id = row.id
      // this.showUploadVisible = true
      this.title = '选择附件'
    },
    handleShow(index, row) {
      this.title = '查看'
      this.editForm.id = row.id
      this.editForm.categoryName = row.categoryName
      this.editForm.imageList = row.images.split(',')
      this.editForm.title = row.title
      this.editForm.contents = row.content
      this.showFormVisible = true
      this.editForm.resourceName = row.resourceName
      this.editForm.resourcePath = row.resourcePath
      // this.handleConfig(index, row)
    },
    async weightChange(row) {
      let res = await weightChange({ articleId: row.id, weight: row.weight })
      if (res.code === 200) {
        this.$message({
          type: 'success',
          message: '设置成功!'
        })
      } else {
        this.$message({
          type: 'error',
          message: res.message
        });
      }
    },
    async showLinkDialog(index, row) {
      console.log("showLinkDialog", index, row)
      let res = await getChannelListReq()
      if (res.code === 200) {
        this.channelList = res.data
        this.showLink = true
        for (let channel of this.channelList) {
          channel.link = "https://wxminiapp.fastemschina.com/#/wx?channelId=" + channel.id + "&sceneId=" + row.id
        }
      } else {
        this.$message({
          title: res.message
        });
      }
      console.log("res", res)
    },
    // 编辑、增加页面保存方法
    submitForm(editData) {
      this.$refs[editData].validate(async valid => {
        if (valid) {
          this.editForm.content = this.editForm.contents
          if (!this.editForm.id) {
            this.editForm.id = ""
            for (let item of this.options) {
              if (item.value == this.editForm.categoryId) {
                this.editForm.categoryName = item.label
                break
              }
            }
            this.editForm.images = this.editForm.imageList.join(",")
            // if (this.editForm.images == '') {
            //   this.$message({
            //     type: 'error',
            //     message: '请上传图片！'
            //   });
            //   return
            // }
            if (this.editForm.content == '') {
              this.$message({
                type: 'error',
                message: '请填写内容！'
              });
              return
            }
            let res = await addArticle(this.editForm)
            if (res.code === 200) {
              this.$message({
                type: 'success',
                message: '添加成功！'
              })
              this.formInline.page = 1
              this.getdata(this.formInline)
            } else {
              this.$message({
                type: 'error',
                message: res.message
              });
            }
          } else {
            this.editForm.images = this.editForm.imageList.join(",")
            for (let item of this.options) {
              if (item.value == this.editForm.categoryId) {
                this.editForm.categoryName = item.label
                break
              }
            }
            let res = await editArticle(this.editForm)
            if (res.code === 200) {
              this.$message({
                type: 'success',
                message: '修改成功！'
              })
              this.resetEditForm()
              this.getdata(this.formInline)
            } else {
              this.$message({
                type: 'error',
                message: res.message
              });
            }
          }
          this.editFormVisible = false
          this.resetEditForm()
          this.$refs[editData].resetFields()
        } else {
          return false
        }
      })
    },// 编辑、增加页面保存方法
    async submitConfigForm() {
      let params = {}
      for (let item of this.configList) {
        for (let key in item) {
          if (key != 'name' && key != 'isNecessary' && key != 'isEnable') {
            params[key] = item.isNecessary ? 2 : item.isEnable ? 1 : 0
          }
        }
      }
      params.categoryId = this.editForm.id
      let res = await configCategory(params)
      if (res.code === 200) {
        this.$message({
          type: 'success',
          message: '配置成功！'
        });
        this.getdata()
      } else {
        this.$message({ type: 'error', message: res.message });
      }
      this.configList = []
      this.editForm.id = null
      this.configFormVisible = false
      this.showcatelog = false
      this.resetEditForm()
    },
    renameResource(row) {
      this.$prompt('请输入附件名', '修改', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal: false,
        //默认值
        inputValue: row.resourceName,
        inputPattern: /^[\s\S]*.*[^\s][\s\S]*$/,
        //输入框错误提示
        inputErrorMessage: '附件名不能为空'
      }).then(async ({ value }) => {

        let res = await renameResource({ resourceName: value, resourceId: row.id })
        if (res.code === 200) {
          this.$message({
            type: 'success',
            message: '修改成功！'
          })
          this.getResourceData()
        } else {
          this.$message({ type: 'error', message: res.message });
        }

      }).catch(() => {

      });
    },
    deleteResourceHandle(row) {
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        let res = await deleteResource({ resourceId: row.id })
        if (res.code === 200) {
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
          this.getResourceData()
        }

      }).catch(() => {

      });
    },
    async copyLink(row) {
      try {
        await navigator.clipboard.writeText(row.link);
        this.$message({ type: 'success', message: '链接已复制到剪贴板' });
      } catch (err) {
        this.$message({ type: 'fail', message: '复制失败' });

      }
    },
    // 删除文章类目
    handleDelete(index, row) {
      this.$confirm('确定要删除吗?', '信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          let res = await deleteArticle({ articleId: row.id })
          if (res.code === 200) {
            this.$message({
              type: 'success',
              message: '删除成功！'
            })
            this.formInline.page = 1
            this.getdata(this.formInline)
          } else {
            this.$message({
              type: 'error',
              message: res.message
            });
          }

        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    // 关闭编辑、增加弹出框
    closeDialog(formName) {
      this.editFormVisible = false
      this.showResourceDialog = false
      this.$refs[formName].resetFields()
      this.resetEditForm()
    },
    closeLinkDialog() {
      this.showLink = false
      this.channelList
    },
    closeConfigDialog(formName) {
      this.configFormVisible = false
      this.showcatelog = false
      // this.$refs[formName].resetFields()
      this.resetEditForm()
    },
    beforeAvatarUpload() {

    },
    beforeFileUpload() {
      this.loadingInstance = this.$loading({ text: '上传中', background: true })
    },
    handleAvatarSuccess(response) {
      console.log("上传成功", response)
      if (response.code === 200) {
        this.editForm.imageList.push(response.data)
        this.$message({
          type: 'success',
          message: '图片上传成功！'
        })
      } else {
        this.$message({ type: 'error', message: response.message });
        //定时2秒
        setTimeout(() => {
          router.push({ path: '/login' })
        }, 1000);
      }
    },
    async handleFileSuccess(response) {
      if (response.code === 200) {
        let filePath = response.data.resourcePath
        let fileName = response.data.resourceName
        //2秒定时器
        this.submitResource(fileName, filePath)
      } else {
        this.loadingInstance.close()
        this.$message({ type: 'error', message: response.message });
        //定时2秒
        setTimeout(() => {
          router.push({ path: '/login' })
        }, 1000);
      }
    },

    handleFileFail(err, file) {
      this.loadingInstance.close()
      if (file.size > 20970000) {
        this.$message({ type: 'error', message: '附件大小不能超过20M' });
      } else {
        this.$message({ type: 'error', message: '上传附件失败,请检查网络' });
      }
    },
    async submitResource(fileName, filePath) {
      let res = await addResource({ resourceName: fileName, resourcePath: filePath })
      setTimeout(() => {
        this.loadingInstance.close()
        this.$message({ type: 'success', message: '附件上传成功！' })
        this.getResourceData()
      }, 1000);
    },
    resourceRadioChange(id) {
      this.submitArticleResource(id)
    },
    async submitArticleResource(id) {
      let res = await submitResource({ articleId: this.editForm.id, resourceId: id })
      if (res.code === 200) {
        this.$message({
          type: 'success',
          message: '附件设置成功！'
        })
        this.showResourceDialog = false
        this.getdata()
      } else {
        this.$message({ type: 'error', message: res.message });
      }
      this.resetEditForm()
    },

    resetEditForm() {
      this.editForm.id = ''
      this.editForm.categoryName = ''
      this.editForm.images = ''
      this.editForm.imageList = []
      this.editForm.categoryId = null
      this.editForm.title = ''
      this.editForm.contents = ''
      this.editForm.content = ''
      this.editForm.resourceName = ''
      this.editForm.resourcePath = ''
    },
    async switchChange(row) {
      let res = await isEnableChange({ articleId: row.id, isEnable: row.enable ? 1 : 0 })
      if (res.code === 200) {
        this.$message({
          type: 'success',
          message: row.enable ? '上架成功' : '下架成功'
        })
        row.isEnable = row.enable ? 1 : 0
      } else {
        this.$message({
          type: 'error',
          message: res.message
        });
      }
    },
    async switchRecommendChange(row) {
      row.recommend = !row.recommend
      let res = await isRecommendChange({ articleId: row.id, isRecommend: row.recommend ? 1 : 0 })
      if (res.code === 200) {
        this.$message({
          type: 'success',
          message: row.recommend ? '推荐成功' : '取消成功'
        })
      } else {
        this.$message({
          type: 'error',
          message: res.message
        });
      }
    },
    filterHandler(value, row, column) {
      return row.enable === value;
    },
    recommendFilterHandler(value, row, column) {
      return row.recommend === value;
    },
    closeShowDialog() {
      this.showFormVisible = false
      this.resetEditForm()
    },
    deleteImage(images, index) {
      if (this.showFormVisible) {
        return
      }
      this.$confirm('确定删除图片?', '信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          images.splice(index, 1)
        })
        .catch(() => {

        })

    },
    closeUploadDialog() {
      this.showUploadVisible = false
    },
    uploadSubmit() {
      this.showUploadVisible = false
    },
    reserFormInLine() {
      this.formInline.title = ''
      this.formInline.startDate = ''
      this.formInline.endDate = ''
      this.formInline.categoryId = ''
      this.formInline.date = ''
    }

  }
}
</script>

<style scoped>
.user-search {
  margin-top: 20px;
}

.userRole {
  width: 100%;
}

.avatar-uploader {
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  width: 80px;
  height: 80px;
  overflow: hidden;
}

.avatar-uploader-border {
  border: 1px dashed #8c939d;
}

.avatar-uploader:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
}

.avatar-container {
  display: flex;
  line-height: 80px;
  margin-left: 49px;
  margin-bottom: 10px;
}

.avatar {
  width: 80px;
  height: 80px;
  display: block;
}

.m-r-10 {
  margin-right: 10px;
}

.form-label {
  border: 1px solid #e0e0e0;
  width: 205px;
  height: 30px;
  line-height: 30px;
  padding-left: 10px;
  border-radius: 5px;
}

.m-l-38 {
  margin-left: 38px;
}

.m-l-48 {
  margin-left: 48px;
}

.m-r-10 {
  margin-right: 10px;
}

.l-h-30 {
  line-height: 30px;
}

.h-340 {
  height: 340px;
}

.m-r-40 {
  margin-right: 40px;
}

.m-t-10 {
  margin-top: 10px;
}

.m-t-40 {
  margin-top: 40px;
}
</style>