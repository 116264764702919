<template>
  <div>

    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right" style="width: 60%;">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>数据总览-访客</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 搜索筛选 -->
    <el-form :inline="true" :model="formInline" class="user-search" style="width: 100%;">

      <el-form-item label="搜索">
        <el-input size="small" v-model="formInline.username" placeholder="输入用户名" clearable></el-input>

      </el-form-item>
      <el-form-item>
        <el-date-picker v-model="formInline.date" @change="dateChange" type="daterange" range-separator="至"
          start-placeholder="开始日期" end-placeholder="结束日期" size="small" :default-time="['00:00:00', '23:59:59']">
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button size="small" type="primary" icon="el-icon-search" @click="searchHandle">搜索</el-button>
        <el-button size="small" type="info" icon="el-icon-refresh-right" @click="resetHandle">重置</el-button>
      </el-form-item>
    </el-form>
    <el-radio-group v-model="tabPosition" style="margin-bottom: 2px;margin-right: 10px;" @input="tabClickHandle">
      <el-radio-button label="7">近7月</el-radio-button>
      <el-radio-button label="14">近14月</el-radio-button>
      <el-radio-button label="30">近30月</el-radio-button>
    </el-radio-group>
    <!--列表-->
    <el-table size="small" :data="listData" highlight-current-row v-loading="loading" border
      element-loading-text="拼命加载中" style="width: 80%;">
      <el-table-column align="center" type="index" label="序号" min-width="100">
      </el-table-column>
      <!-- <el-table-column align="center" prop="image" label="图片"  min-width="50" show-overflow-tooltip>
        <template slot-scope="scope">
          <img :src="scope.row.image" style="width: 50px; height: 50px;">
        </template>
</el-table-column> -->
      <!-- <el-table-column align="center" prop="articleTitle" label="文章标题" min-width="60" show-overflow-tooltip>
      </el-table-column> -->

      <el-table-column align="center" prop="username" label="用户名" min-width="60" show-overflow-tooltip>
      </el-table-column>
      <el-table-column align="center" prop="openid" label="openid" min-width="60" show-overflow-tooltip>
      </el-table-column>
      <el-table-column align="center" prop="channel" label="渠道" min-width="60" show-overflow-tooltip>
      </el-table-column>

      <el-table-column align="center" prop="createTime" label="最近访问日期" min-width="50" show-overflow-tooltip>
        <template slot-scope="scope">
          <div>{{ scope.row.createTime | timestampToDate }}</div>
        </template>
      </el-table-column>
      <!-- <el-table-column align="center" label="操作" width="285px">
        <template slot-scope="scope">
          <el-button size="mini" type="primary" @click="handleShow(scope.$index, scope.row)">查看</el-button>
          <el-button size="mini" type="success" @click="handleConfig(scope.$index, scope.row)">附件</el-button>
          <el-button size="mini" type="warning" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
          <el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
        </template>
      </el-table-column> -->
    </el-table>
    <!-- 分页组件 -->
    <el-pagination @current-change="handleCurrentChange" :hide-on-single-page="true"
      :current-page.sync="formInline.page" :page-count="formInline.totalPage" layout="prev, pager, next, jumper"
      :total="formInline.total">
    </el-pagination>
  </div>
</template>

<script>
import { getVisitorRecordReq } from '../../api/recordMG'
import { timestampToDateRangeEnd, timestampToDateRangeStart } from '@/utils/util'
import { getChannelListReq } from '../../api/configMG'
export default {
  data() {
    return {
      loading: false, //是显示加载
      options: [],

      formInline: {
        page: 1,
        pageSize: 10,
        totalPage: 1,
        total: 0,
        startDate: '',
        endDate: '',
        channelId: '',
        id: '',
        date: [],
        username: ''
      },
      listData: [], //类目数据
      channelList: [],
      tabPosition: '7'
    }
  },

  /**
   * 数据发生改变
   */

  /**
   * 创建完毕
   */
  created() {
    if (this.$route.query.startDate && this.$route.query.endDate) {
      this.tabPosition = ''
      this.formInline.startDate = this.$route.query.startDate
      this.formInline.endDate = this.$route.query.endDate
      this.formInline.date[0] = this.$route.query.startDate
      this.formInline.date[1] = this.$route.query.endDate
    } else {
      this.initFormInline()

    }
  },
  async mounted() {
    await this.getChannelList()
    this.search()
  },
  /**
   * 里面的方法只有被调用才会执行
   */
  methods: {
    //日期选择器中日期变化时触发
    dateChange() {
      if (this.formInline.date) {
        this.formInline.startDate = timestampToDateRangeStart(this.formInline.date[0])
        this.formInline.endDate = timestampToDateRangeEnd(this.formInline.date[1])
      } else {
        this.formInline.startDate = ''
        this.formInline.endDate = ''
      }

    },
    handleCurrentChange() {
      this.search()
    },
    // 搜索事件
    async search() {
      //this.loading = true
      let res = await getVisitorRecordReq(this.formInline)
      if (res.data && res.code === 200) {
        this.listData = res.data.records
        this.formInline.totalPage = res.data.pages
        this.formInline.total = res.data.total
      }
    },
    async getChannelList() {
      let res = await getChannelListReq()
      if (res.code === 200) {
        this.channelList = res.data
      }
    },
    resetFormInLine() {
      this.formInline = {
        page: 1,
        pageSize: 10,
        totalPage: this.formInline.totalPage,
        total: this.formInline.total,
        startDate: '',
        endDate: '',
        channelId: '',
        id: '',
        date: [],
        username: ''
      }
    },
    searchHandle() {
      this.tabPosition = ''
      if (!this.formInline.date || this.formInline.date.length < 1) {
        this.formInline.startDate = ''
        this.formInline.endDate = ''
      }
      this.formInline.page = 1
      this.search()
    },
    tabClickHandle() {
      this.resetFormInLine()
      let dataArray = []
      if (this.tabPosition == '7') {
        dataArray = this.getDateArrayByDays(7 * 30);
      } else if (this.tabPosition == '14') {
        dataArray = this.getDateArrayByDays(14 * 30);
      } else if (this.tabPosition == '30') {
        dataArray = this.getDateArrayByDays(30 * 30);
      }
      if (dataArray) {
        this.formInline.startDate = timestampToDateRangeStart(dataArray[0])
        this.formInline.endDate = timestampToDateRangeEnd(dataArray[1].toISOString())
      }

      this.search()
    },
    getDateArrayByDays(days = 7) {
      let endDate = new Date();
      let startDate = new Date(endDate.getTime() - days * 24 * 60 * 60 * 1000); //30天 * 24小时/天 * 60分钟/小时 * 60秒/分钟 * 1000毫秒/秒
      return [startDate, endDate];
    },
    initFormInline() {
      let dataArray = this.getDateArrayByDays(7 * 30);
      this.formInline.startDate = timestampToDateRangeStart(dataArray[0])
      this.formInline.endDate = timestampToDateRangeEnd(dataArray[1])
    },
    resetHandle() {
      this.resetFormInLine()
      this.initFormInline()
      this.tabPosition = '7'
      this.search()
    }
  }
}
</script>

<style scoped>
.user-search {
  margin-top: 20px;
}

.userRole {
  width: 100%;
}

.avatar-uploader {
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  width: 80px;
  height: 80px;
  overflow: hidden;
}

.avatar-uploader-border {
  border: 1px dashed #8c939d;
}

.avatar-uploader:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
}

.avatar-container {
  display: flex;
  line-height: 80px;
  margin-left: 49px;
  margin-bottom: 10px;
}

.avatar {
  width: 80px;
  height: 80px;
  display: block;
}

.m-r-10 {
  margin-right: 10px;
}

.form-label {
  border: 1px solid #e0e0e0;
  width: 205px;
  height: 30px;
  line-height: 30px;
  padding-left: 10px;
  border-radius: 5px;
}

.m-l-38 {
  margin-left: 38px;
}

.m-l-48 {
  margin-left: 48px;
}

.m-r-10 {
  margin-right: 10px;
}

.l-h-30 {
  line-height: 30px;
}

.h-340 {
  height: 340px;
}

.m-r-40 {
  margin-right: 40px;
}

.m-t-10 {
  margin-top: 10px;
}

.m-t-40 {
  margin-top: 40px;
}
</style>