<template>
  <div>

    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right" style="width: 60%;">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>用户列表</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 搜索筛选 -->
    <el-form :inline="true" :model="formInline" class="user-search" style="width: 100%;">
      <el-form-item label="搜索">
        <el-input size="small" v-model="formInline.username" placeholder="输入用户名" style="width:150px"
          clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-input size="small" v-model="formInline.phoneNumber" placeholder="输入手机号码" style="width:200px"
          clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-input size="small" v-model="formInline.openid" placeholder="输入openid" style="width:200px"
          clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-select v-model="formInline.type" placeholder="用户类型" size="small" clearable>
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-button size="small" type="primary" icon="el-icon-search" @click="search">搜索</el-button>
        <el-button size="small" type="info" icon="el-icon-refresh-right" @click="reserFormInLine">重置</el-button>
        <!-- <el-button size="small" type="primary" icon="el-icon-plus" @click="handleEdit()">添加</el-button> -->
      </el-form-item>
    </el-form>
    <!--列表-->
    <el-table size="small" :data="listData" highlight-current-row v-loading="loading" border
      element-loading-text="拼命加载中" style="width: 100%;">
      <el-table-column align="center" type="index" label="序号" min-width="100">
      </el-table-column>
      <el-table-column align="center" prop="username" label="用户名" min-width="35" show-overflow-tooltip>
        <template slot-scope="scope">
          <div>{{ scope.row.username ? scope.row.username : '' }}</div>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="phoneNumber" label="手机号码" min-width="50" show-overflow-tooltip>
        <template slot-scope="scope">
          <div>{{ scope.row.phoneNumber ? scope.row.phoneNumber : '' }}</div>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="openid" label="openid" min-width="80" show-overflow-tooltip>

      </el-table-column>
      <el-table-column align="center" prop="isRegister" label="用户类型" min-width="35" show-overflow-tooltip>
        <template slot-scope="scope">
          <div>{{ scope.row.isRegister == 1 ? '注册用户' : '游客' }}</div>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="channel" label="首次访问渠道" min-width="45" show-overflow-tooltip>
      </el-table-column>

      <el-table-column align="center" prop="credits" label="积分" min-width="30" show-overflow-tooltip>
      </el-table-column>
      <el-table-column align="center" prop="level" label="等级" min-width="25" show-overflow-tooltip>
      </el-table-column>
      <el-table-column align="center" prop="memo" label="标签" min-width="40" show-overflow-tooltip>
      </el-table-column>
      <el-table-column align="center" prop="enable" label="是否启用" min-width="40" show-overflow-tooltip
        :filters="enableFilters" :filter-method="filterHandler">
        <template slot-scope="scope">
          <el-switch v-model="scope.row.enable" @change="switchChange(scope.row)" active-color="#13ce66"
            inactive-color="#ff4949">
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="firstVisit" label="首次访问时间" min-width="45" show-overflow-tooltip>
        <template slot-scope="scope">
          <div>{{ scope.row.firstVisit | timestampToDate }}</div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" width="300">
        <template slot-scope="scope">
          <div>
            <el-button size="mini" type="primary" @click="handleShow(scope.$index, scope.row)">用户标签</el-button>
            <el-button size="mini" type="primary" @click="toShareRecord(scope.$index, scope.row)">转发记录</el-button>
            <el-button size="mini" type="primary" @click="toReadingRecord(scope.$index, scope.row)">阅读记录</el-button>
          </div>
          <div style="margin-top: 10px;">
            <el-button size="mini" :disabled="scope.row.isRegister == 1 ? false : true" type="primary"
              @click="toCreditDetail(scope.$index, scope.row)">积分明细</el-button>
            <el-button size="mini" :disabled="scope.row.isRegister == 1 ? false : true" type="primary"
              @click="toDownloadRecord(scope.$index, scope.row)">下载明细</el-button>
            <el-button size="mini" :disabled="scope.row.isRegister == 1 ? false : true" type="primary"
              @click="toOrderList(scope.$index, scope.row)">兑换记录</el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页组件 -->
    <el-pagination @current-change="handleCurrentChange" :hide-on-single-page="true"
      :current-page.sync="formInline.page" :page-count="formInline.totalPage" layout="prev, pager, next, jumper"
      :total="formInline.total">
    </el-pagination>
    <!-- 编辑界面 -->
    <el-dialog title="用户标签" :visible.sync="editFormVisible" width="450px" @click="closeDialog('editForm')">

      <el-form label-width="120px" :model="editForm" :rules="rules" ref="editForm">
        <el-form-item label="用户标签" prop="memo">
          <el-input style="width:90%;" size="small" v-model="editForm.memo" auto-complete="off"
            placeholder="请输入用户标签"></el-input>
        </el-form-item>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="closeDialog('editForm')">取消</el-button>
        <el-button size="small" type="primary" :loading="loading" class="title"
          @click="submitForm('editForm')">保存</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import router from '@/router'
import { getUserList, editUserMemoReq, changeUserIsEnableReq } from '../../api/userMG'
export default {
  data() {
    return {
      loading: false, //是显示加载
      editFormVisible: false, //控制编辑页面显示与隐藏
      title: '添加',
      editForm: {
        id: null,
        memo: '',
      },
      // rules表单验证
      rules: {
        memo: [{ required: true, message: '请输入用户标签', trigger: 'blur' }],
      },
      formInline: {
        page: 1,
        totalPage: 1,
        total: 0,
        pageSize: 10,
        username: '',
        phoneNumber: '',
        type: '',
        openid: ''
      },

      listData: [], //类目数据
      enableFilters: [{ text: '启用', value: true }, { text: '禁用', value: false }],
      options: [{ value: 0, label: '游客' }, { value: 1, label: '注册用户' }]
    }
  },

  /**
   * 数据发生改变
   */

  /**
   * 创建完毕
   */
  created() {

  },
  mounted() {
    this.getdata()
  },
  /**
   * 里面的方法只有被调用才会执行
   */
  methods: {
    // 获取公司列表
    async getdata() {
      //this.loading = true
      let res = await getUserList(this.formInline)
      if (res.data && res.code === 200) {

        this.listData = res.data.records
        for (let item of this.listData) {
          item.enable = item.isEnable == 1 ? true : false
        }
        this.formInline.total = res.data.total
        this.formInline.totalPage = res.data.pages
      }
    },
    //日期选择器中日期变化时触发
    dateChange() {
      if (this.formInline.date) {
        this.formInline.startDate = this.formInline.date[0]
        this.formInline.endDate = this.formInline.date[1]
      } else {
        this.formInline.startDate = ''
        this.formInline.endDate = ''
      }

    },

    handleCurrentChange() {
      this.getdata()
    },
    // 搜索事件
    async search() {
      this.getdata()
    },
    //显示编辑界面
    handleEdit: function (index, row) {
      this.editFormVisible = true
      if (row != undefined && row != 'undefined') {
        this.title = '修改'
        this.editForm.id = row.id
        this.editForm.title = row.title
        this.editForm.images = row.images
        this.editForm.credits = row.credits
      } else {
        this.title = '添加'
        this.editForm.id = null
        this.editForm.categoryName = ''
        this.editForm.image = null
      }
    },

    handleShow(index, row) {
      this.editFormVisible = true
      this.editForm.memo = row.memo
      this.editForm.id = row.id
    },
    filterHandler(value, row, column) {
      return row.enable === value;
    },
    // 编辑、增加页面保存方法
    submitForm(editData) {

      this.$refs[editData].validate(async valid => {
        if (valid) {
          let res = await editUserMemoReq(this.editForm)
          if (res.code === 200) {
            this.$message({
              type: 'success',
              message: '打标成功！'
            })
            this.search(this.formInline)
          } else {
            this.$message({
              type: 'error',
              message: res.message
            });
          }
          this.editFormVisible = false
          this.resetEditForm()
        } else {
          return false
        }
      })
    },// 编辑、增加页面保存方法


    // 关闭编辑、增加弹出框
    closeDialog() {
      this.editFormVisible = false
      this.showGift = false
      this.resetEditForm()
    },


    resetEditForm() {
      this.editForm.id = ''
      this.editForm.memo = ''
    },
    async switchChange(row) {
      let params = {
        id: row.id,
        isEnable: row.enable ? 1 : 0
      }
      let res = await changeUserIsEnableReq(params)
      if (res.code === 200) {
        this.$message({
          type: 'success',
          message: row.enable ? '启用成功！' : '禁用成功！'
        })
        this.getdata()
      } else {
        this.$message({
          type: 'error',
          message: res.message
        });
      }

    },
    reserFormInLine() {
      this.formInline = {
        page: 1,
        totalPage: 1,
        total: 0,
        pageSize: 10,
        username: '',
        phoneNumber: '',
        type: ''
      }
    },
    toReadingRecord(index, row) {
      router.push({
        path: '/user/ReadingRecordList',
        query: {
          userId: row.id
        }
      })
    },
    toDownloadRecord(index, row) {
      router.push({
        path: '/user/UserDownloadRecordList',
        query: {
          userId: row.id
        }
      })
    },
    toShareRecord(index, row) {
      router.push({
        path: '/user/ShareRecordList',
        query: {
          userId: row.id
        }
      })
    },
    toCreditDetail(index, row) {
      router.push({
        path: '/user/CreditDetail',
        query: {
          userId: row.id
        }
      })
    },
    toOrderList(index, row) {
      router.push({
        path: '/user/UserOrderList',
        query: {
          userId: row.id
        }
      })
    }

  }
}
</script>

<style scoped>
.user-search {
  margin-top: 20px;
}

.userRole {
  width: 100%;
}

.avatar-uploader {
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  width: 80px;
  height: 80px;
  overflow: hidden;
}

.avatar-uploader-border {
  border: 1px dashed #8c939d;
}

.avatar-uploader:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
}

.avatar-container {
  display: flex;
  line-height: 80px;
  margin-left: 49px;
  margin-bottom: 10px;
}

.avatar {
  width: 80px;
  height: 80px;
  display: block;
}

.m-r-10 {
  margin-right: 10px;
}
</style>