<template>
  <div>

    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right" style="width: 60%;">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>渠道列表</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 搜索筛选 -->
    <el-form :inline="true" :model="formInline" class="user-search" style="width: 100%;">
      <el-form-item label="搜索">
        <el-input size="small" v-model="formInline.channelName" placeholder="输入渠道名称" clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-date-picker v-model="formInline.date" @change="dateChange" type="daterange" range-separator="至"
          start-placeholder="开始日期" end-placeholder="结束日期" size="small" value-format="yyyy-MM-dd HH:mm:ss"
          :default-time="['00:00:00', '23:59:59']">
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button size="small" type="primary" icon="el-icon-search" @click="search">搜索</el-button>
        <el-button size="small" type="info" icon="el-icon-refresh-right" @click="reserFormInLine">重置</el-button>
        <el-button size="small" type="primary" icon="el-icon-plus" @click="handleEdit()">添加</el-button>
      </el-form-item>
    </el-form>
    <!--列表-->
    <el-table size="small" :data="listData" highlight-current-row v-loading="loading" border
      element-loading-text="拼命加载中" style="width: 60%;">
      <el-table-column align="center" type="index" label="序号" min-width="100">
      </el-table-column>
      <el-table-column align="center" prop="channelName" label="渠道名称" min-width="100" show-overflow-tooltip>
      </el-table-column>
      <el-table-column align="center" prop="createTime" label="创建时间" mi-width="100" show-overflow-tooltip>
        <template slot-scope="scope">
          <div>{{ scope.row.createTime | timestampToDate }}</div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" min-width="100">
        <template slot-scope="scope">
          <el-button size="mini" type="warning" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
          <el-button size="mini" type="danger" @click="deleteUser(scope.$index, scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页组件 -->
    <el-pagination @current-change="handleCurrentChange" :hide-on-single-page="true"
      :current-page.sync="formInline.page" :page-count="formInline.totalPage" layout="prev, pager, next, jumper"
      :total="formInline.total">
    </el-pagination>
    <!-- 编辑界面 -->
    <el-dialog :title="title" :visible.sync="editFormVisible" width="30%" @click="closeDialog('editForm')">
      <el-form label-width="120px" :model="editForm" :rules="rules" ref="editForm">
        <el-form-item label="渠道名称" prop="channelName">
          <el-input size="small" v-model="editForm.channelName" auto-complete="off" placeholder="请输入渠道名称"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="closeDialog('editForm')">取消</el-button>
        <el-button size="small" type="primary" :loading="loading" class="title"
          @click="submitForm('editForm')">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { addChannel, editChannel, deleteChannel, getChannelByCondition } from '../../api/configMG'
export default {
  data() {
    return {
      loading: false, //是显示加载
      editFormVisible: false, //控制编辑页面显示与隐藏
      title: '添加',

      editForm: {
        id: null,
        channelName: '',
      },
      // rules表单验证
      rules: {
        channelName: [{ required: true, message: '请输入渠道名称', trigger: 'blur' }],

      },
      formInline: {
        page: 1,
        totalPage: 1,
        total: 0,
        channelName: '',
        date: [],
        startDate: '',
        endDate: '',
      },

      listData: [], //用户数据
      // 分页参数
      pageparm: {
        currentPage: 1,
        pageSize: 10,
        total: 10
      }
    }
  },

  /**
   * 数据发生改变
   */

  /**
   * 创建完毕
   */
  created() {

  },
  mounted() {
    this.getdata()
  },
  /**
   * 里面的方法只有被调用才会执行
   */
  methods: {
    // 获取公司列表
    async getdata(parameter) {
      //this.loading = true
      let res = await getChannelByCondition(this.formInline)
      if (res.data && res.code === 200) {
        this.listData = res.data.channelList
        this.formInline.totalPage = res.data.totalPage
        this.formInline.total = res.data.total
      }
    },
    //日期选择器中日期变化时触发
    dateChange() {
      if (this.formInline.date) {
        this.formInline.startDate = this.formInline.date[0]
        this.formInline.endDate = this.formInline.date[1]
      } else {
        this.formInline.startDate = ''
        this.formInline.endDate = ''
      }

    },

    handleCurrentChange() {
      this.getdata()
    },
    // 搜索事件
    async search() {
      this.getdata(this.formInline)
    },
    //显示编辑界面
    handleEdit: function (index, row) {
      this.editFormVisible = true
      if (row != undefined && row != 'undefined') {
        this.title = '修改'
        this.editForm.id = row.id
        this.editForm.channelName = row.channelName
      } else {
        this.title = '添加'
        this.editForm.id = null
        this.editForm.channelName = ''
      }
    },
    // 编辑、增加页面保存方法
    submitForm(editData) {
      this.$refs[editData].validate(async valid => {
        if (valid) {
          if (!this.editForm.id) {
            this.editForm.id = ""
            let res = await addChannel(this.editForm)
            if (res.code === 200) {
              this.$message({
                type: 'success',
                message: '添加成功！'
              })
              this.formInline.page = 1
              this.getdata(this.formInline)
            } else {
              this.$message({
                type: 'error',
                message: res.message
              });
            }
          } else {
            let res = await editChannel(this.editForm)
            if (res.code === 200) {
              this.$message({
                type: 'success',
                message: '修改成功！'
              })
              this.getdata(this.formInline)
            } else {
              this.$message({
                type: 'error',
                message: res.message
              });
            }
          }
          this.editFormVisible = false
          this.$refs[formName].resetFields()
        } else {
          return false
        }
      })
    },
    // 删除公司
    deleteUser(index, row) {
      this.$confirm('确定要删除吗?', '信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          let res = await deleteChannel({ channelId: row.id })
          if (res.code === 200) {
            this.$message({
              type: 'success',
              message: '修改成功！'
            })
            this.formInline.page = 1
            this.getdata(this.formInline)
          } else {
            this.$message({
              type: 'error',
              message: res.message
            });
          }

        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    // 关闭编辑、增加弹出框
    closeDialog(formName) {
      this.editFormVisible = false
      this.$refs[formName].resetFields()
    },
    reserFormInLine() {
      this.formInline = {
        page: 1,
        totalPage: 1,
        total: 0,
        channelName: '',
        date: [],
        startDate: '',
        endDate: '',
      }
    }
  }
}
</script>

<style scoped>
.user-search {
  margin-top: 20px;
}

.userRole {
  width: 100%;
}
</style>