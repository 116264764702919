import { req } from './axiosFun';




/**
 * 渠道配置
 */
//新增渠道
export const addChannel = (params) => { return req("post", "/channel/create", params) };
export const editChannel = (params) => { return req("put", "/channel/edit", params) };
export const deleteChannel = (params) => { return req("delete", "/channel", params) };

export const getChannel = (params) => { return req("get", "/channel/get_by_condition?page=" + params.page, params) };
export const getChannelByCondition = (params) => {
    return req("get", "/channel/get_by_condition?page=" + params.page + "&channelName=" + params.channelName + "&startDate=" + params.startDate + "&endDate=" + params.endDate, params)
};
export const getChannelListReq = (params) => { return req("get", "/channel/get_all", params) };
/**
 * 文章类目配置
 */
export const getCategoryByCondition = (params) => {
    return req("get", "/category/get_by_condition?page=" + params.page + "&categoryName=" + params.categoryName + "&startDate=" + params.startDate + "&endDate=" + params.endDate, params)
};
export const addCategory = (params) => { return req("post", "/category/create", params) };
export const deleteCategory = (params) => { return req("delete", "/category", params) };
export const editCategory = (params) => { return req("put", "/category/edit", params) };
export const configCategory = (params) => { return req("put", "/category/config", params) };
export const getCategoryList = (params) => { return req("get", "/category/get_category_list", params) };

/**
 * 轮播图配置
 */

export const addHomeImage = (params) => { return req("post", "/config/create_home_image", params) };
export const getHomeImages = (params) => { return req("get", "/config/get_home_images", params) };
export const editHomeImage = (params) => { return req("put", "/config/edit_home_image", params) };
export const deleteHomeImage = (params) => { return req("delete", "/config/delete_home_page_by_id", params) };

/**
 * 礼物
 */
export const addGift = (params) => { return req("post", "/gift/create", params) };
export const getGiftByCondition = (params) => {
    return req("get", "/gift/get_by_condition?page=" + params.page + "&pageSize=" + params.pageSize + "&title=" + params.title + "&startDate=" + params.startDate + "&endDate=" + params.endDate, params)
};
export const deleteGift = (params) => { return req("delete", "/gift", params) };
export const editGift = (params) => { return req("put", "/gift/edit", params) };
export const changeGiftEnableReq = (params) => { return req("put", "/gift/change_isEnable", params) };
/**
 * 积分设置
 */
export const getCreditRules = (params) => { return req("get", "/config/get_credits", params) };
export const editCreditRules = (params) => { return req("put", "/config/credit_config", params) };

/**
 * 等级设置
 */
export const getLevelRules = (params) => { return req("get", "/config/get_level", params) };
export const editLevelRules = (params) => { return req("put", "/config/edit_level_rule", params) };
/**
 * 附件配置
 */
export const getReourceList = (params) => { return req("get", "/resource/get_list", params) };
export const addResource = (params) => { return req("post", "/resource/submit", params) };
export const renameResource = (params) => { return req("put", "/resource/rename", params) };
export const deleteResource = (params) => { return req("delete", "/resource", params) };
export const getHomeUrlReq = (params) => { return req("get", "/config/home_url", params) };
export const editHomeUrlReq = (params) => { return req("put", "/config/home_url", params) };

export const getPrivacyUrlReq = (params) => { return req("get", "/config/privacy_url", params) };
export const editPrivacyUrlReq = (params) => { return req("put", "/config/privacy_url", params) };

export const getVedioNoReq = (params) => { return req("get", "/config/vedio_no", params) };
export const editVedioNoReq = (params) => { return req("put", "/config/vedio_no", params) };

/**
 * 用户登出
 */

export const logout = (params) => { return req("post", "/systemUser/logout", params) };
export const editPassword = (params) => { return req("put", "/systemUser/password", params) };


