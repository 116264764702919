// 导入组件
import Vue from 'vue';
import Router from 'vue-router';
// 登录
import login from '@/views/login';
// 首页
import index from '@/views/index';
/**
 * 基础菜单
 */
// 商品管理
//文章列表
import ArticleList from '../views/article/ArticleList.vue';
//渠道列表
import ChannelList from '../views/channel/ChannelList.vue';
//文章类目
import CategoryList from '../views/category/CategoryList.vue';
//轮播图
import CarouselList from '../views/carousel/CarouselList.vue';
//礼物列表
import GiftList from '../views/gift/GiftList.vue';
//订单列表
import OrderList from '../views/order/OrderList.vue';
//用户管理
import UserList from '../views/user/UserList.vue';

//积分设置
import CreditsConfig from '../views/credits/CreditsConfig.vue';

//wx拉起中专
import h5towx from '../views/h5towx/h5towx.vue';
//等级管理
import Level from '../views/level/Level.vue';
//主页设置
import home from '../views/home/home.vue';
//用户隐私协议
import privacy from '../views/privacy/privacy.vue';
import vedio from '../views/vedio/vedio.vue';

//每日数据统计
//每日文章数据统计
import ArticleStatistics from '../views/statistics/ArticleStatistics.vue';
//每日渠道数据tongji
// 数据统计
import DataStatistics from '../views/statistics/DataStatistics.vue';
//附件下载数据统计
import DownloadStatistics from '../views/statistics/DownloadStatistics.vue';
//用户阅读记录
import ReadingRecordList from '../views/user/ReadingRecordList.vue';
//用户附件下载明细
import UserDownloadRecordList from '../views/user/UserDownloadRecordList.vue';
//用户分享记录
import ShareRecordList from '../views/user/ShareRecordList.vue';
//用户积分明细
import CreditDetail from '../views/user/CreditDetail.vue';
//用户兑换记录
import UserOrderList from '../views/user/UserOrderList.vue';
//文章转发记录
import ArticleShareRecordList from '../views/record/ArticleShareRecordList.vue';
//数据总览-访客
import VisitorStatisticsList from '../views/statistics/VisitorStatisticsList.vue';
//用户阅读记录

// 权限管理
import Permission from '@/views/system/Permission';
//用户设置
import UserConfig from '../views/system/UserConfig.vue';
//数据总览
import DataOverview from '../views/statistics/DataOverview.vue';
//下载记录
import DownloadRecordList from '../views/record/DownloadRecordList.vue';
//表单提交记录
import FormSubmitRecordList from '../views/record/FormSubmitRecordList.vue'
//文章浏览记录
import ArticleBrowsingRecordList from '../views/record/ArticleBrowsingRecordList.vue'
//访客记录
import VisitorRecordList from '../views/record/VisitorRecordList.vue'

/**
 * 数据监控
 */
// 监控查询

// 启用路由
Vue.use(Router);

// 导出路由 
export default new Router({
    routes: [{
        path: '/',
        name: '',
        component: login,
        hidden: true,
        meta: {
            requireAuth: false
        }
    }, {
        path: '/login',
        name: '登录',
        component: login,
        hidden: true,
        meta: {
            requireAuth: false
        }
    }, {
        path: '/wx',
        name: '小程序唤起',
        component: h5towx,
        hidden: true,
        meta: {
            requireAuth: true
        }
    },
    {
        path: '/index',
        name: '首页',
        component: index,
        iconCls: 'el-icon-tickets',
        children: [{
            path: '/article/ArticleList',
            name: '文章列表',
            component: ArticleList,
            meta: {
                requireAuth: true
            }
        }, {
            path: '/channel/ChannelList',
            name: '渠道列表',
            component: ChannelList,
            meta: {
                requireAuth: true
            }
        }, {
            path: '/category/CategoryList',
            name: '文章类目',
            component: CategoryList,
            meta: {
                requireAuth: true
            }
        }, {
            path: '/carousel/CarouselList',
            name: '轮播图设置',
            component: CarouselList,
            meta: {
                requireAuth: true
            }
        }, {
            path: '/gift/GiftList',
            name: '礼物管理',
            component: GiftList,
            meta: {
                requireAuth: true
            }
        }, {
            path: '/order/OrderList',
            name: '订单管理',
            component: OrderList,
            meta: {
                requireAuth: true
            }
        }, {
            path: '/user/UserList',
            name: '用户管理',
            component: UserList,
            meta: {
                requireAuth: true
            }
        }, {
            path: '/credits/CreditsConfig',
            name: '积分管理',
            component: CreditsConfig,
            meta: {
                requireAuth: true
            }
        }, {
            path: '/level/Level',
            name: '等级管理',
            component: Level,
            meta: {
                requireAuth: true
            }
        },
        //     {
        //     path: '/statistics/DailyStatistics',
        //     name: '每日数据统计',
        //     component: DailyStatistics,
        //     meta: {
        //         requireAuth: true
        //     }
        // }, {
        //     path: '/statistics/ArticleDailyStatistics',
        //     name: '每日文章数据统计',
        //     component: ArticleDailyStatistics,
        //     meta: {
        //         requireAuth: true
        //     }
        // }, {
        //     path: '/statistics/ChannelDailyStatistics',
        //     name: '每日渠道数据统计',
        //     component: ChannelDailyStatistics,
        //     meta: {
        //         requireAuth: true
        //     }
        //     },
        {
            path: '/system/Permission',
            name: '权限管理',
            component: Permission,
            meta: {
                requireAuth: true
            }
        }, {
            path: '/home/home',
            name: '官网配置',
            component: home,
            meta: {
                requireAuth: true
            }
        }, {
            path: '/privacy/privacy',
            name: '用户隐私协议',
            component: privacy,
            meta: {
                requireAuth: true
            }
        }, {
            path: '/vedio/vedio',
            name: '视频号设置',
            component: vedio,
            meta: {
                requireAuth: true
            }
        }, {
            path: '/system/UserConfig',
            name: '用户设置',
            component: UserConfig,
            meta: {
                requireAuth: true
            }
        }, {
            path: '/statistics/DataOverview',
            name: '数据总览',
            component: DataOverview,
            meta: {
                requireAuth: true
            }
        },
        {
            path: '/record/DownloadRecordList',
            name: '附件下载记录',
            component: DownloadRecordList,
            meta: {
                requireAuth: true
            }
        }, {
            path: '/record/FormSubmitRecordList',
            name: '表单提交记录',
            component: FormSubmitRecordList,
            meta: {
                requireAuth: true
            }
        }, {
            path: '/record/ArticleBrowsingRecordList',
            name: '文章浏览记录',
            component: ArticleBrowsingRecordList,
            meta: {
                requireAuth: true
            }
        }, {
            path: '/record/VisitorRecordList',
            name: '访客记录',
            component: VisitorRecordList,
            meta: {
                requireAuth: true
            }
        }, {
            path: '/statistics/DataStatistics',
            name: '数据统计',
            component: DataStatistics,
            meta: {
                requireAuth: true
            }
        }, {
            path: '/statistics/DownloadStatistics',
            name: '附件下载数据统计',
            component: DownloadStatistics,
            meta: {
                requireAuth: true
            }
        }, {
            path: '/statistics/ArticleStatistics',
            name: '文章浏览数据统计',
            component: ArticleStatistics,
            meta: {
                requireAuth: true
            }
        },
        {
            path: '/user/ReadingRecordList',
            name: '用户阅读记录',
            component: ReadingRecordList,
            meta: {
                requireAuth: true
            }
        },
        {
            path: '/user/UserDownloadRecordList',
            name: '用户附件下载明细',
            component: UserDownloadRecordList,
            meta: {
                requireAuth: true
            }
        },
        {
            path: '/user/ShareRecordList',
            name: '用户附件下载明细',
            component: ShareRecordList,
            meta: {
                requireAuth: true
            }
        }
            ,
        {
            path: '/user/CreditDetail',
            name: '用户附件下载明细',
            component: CreditDetail,
            meta: {
                requireAuth: true
            }
        },
        {
            path: '/user/UserOrderList',
            name: '用户兑换记录',
            component: UserOrderList,
            meta: {
                requireAuth: true
            }
        },
        {
            path: '/record/ArticleShareRecordList',
            name: '用户兑换记录',
            component: ArticleShareRecordList,
            meta: {
                requireAuth: true
            }
        },
        {
            path: '/statistics/VisitorStatisticsList',
            name: '数据总览-访客',
            component: VisitorStatisticsList,
            meta: {
                requireAuth: true
            }
        }]
    }]
})
