import axios from 'axios';
import service from './service';

// 通用公用方法
const req = (method, url, params) => {
    // 根据请求类型设置Content-Type
    let headers = {
        'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
        //  Authorization: localStorage.getItem('accessToken')
    };

    // 如果params是对象，则需要转换为x-www-form-urlencoded格式
    if (typeof params === 'object') {
        headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8';
        params = Object.keys(params).map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`).join('&');
    } else {
        // 如果params是字符串，则保持原样
        headers['Content-Type'] = 'application/json;charset=utf-8';
    }

    return service({
        method: method,
        url: url,
        headers: headers,
        data: params,
        // 注意：不要在这里设置transformRequest，因为它已经在拦截器中处理了
    }).then(res => {
        return res ? res.data : null
    });
};

// 登录请求方法
const loginreq = (method, url, params) => {
    return axios({
        method: method,
        url: url,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        data: params,
        traditional: true,
        transformRequest: [
            function (data) {
                let ret = ''
                for (let it in data) {
                    ret +=
                        encodeURIComponent(it) +
                        '=' +
                        encodeURIComponent(data[it]) +
                        '&'
                }
                return ret
            }
        ]
    }).then(res => res.data);
};


export {
    loginreq,
    req
}
