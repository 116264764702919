import { req } from './axiosFun';

//文章管理
export const addArticle = (params) => { return req("post", "/article/create", params) };

export const getArticleByCondition = (params) => {
    return req("get", "/article/get_articles_by_condition?page=" + params.page + "&title=" + params.title + "&articleId=" + params.articleId + "&categoryId=" + params.categoryId + "&startDate=" + params.startDate + "&endDate=" + params.endDate, params)
};
export const isEnableChange = (params) => { return req("put", "/article/change_isEnable", params) };
export const isRecommendChange = (params) => { return req("put", "/article/change_isRecommend", params) };
export const weightChange = (params) => { return req("put", "/article/weight_change", params) };



export const editArticle = (params) => { return req("put", "/article/edit", params) };

export const deleteArticle = (params) => { return req("delete", "/article", params) };

export const uploadFile = (params) => { return req("post", "/article/upload_file", params) };

export const submitResource = (params) => { return req("post", "/article/submit_resource", params) };



// /**
//  * 支付配置 
//  **/
// // 支付配置-获取支付配置列表
// export const ConfigList = (params) => { return req("post", "/api/Config/list", params) };
// // 支付配置-保存支付配置
// export const ConfigSave = (params) => { return req("post", "/api/Config/save", params) };
// // 支付配置-删除支付配置
// export const ConfigDelete = (params) => { return axios.delete("/api/Config/delete?ids=" + params + "&token=" + localStorage.getItem('logintoken')).then(res => res.data) };

// /**
//  * 订单管理-交易订单 
//  **/
// // 交易订单-获取交易订单列表
// export const OrderList = (params) => { return req("post", "/api/Order/list", params) };
// // 交易订单-s删除交易订单
// export const OrderDelete = (params) => { return axios.delete("/api/Order/delete?ids=" + params + "&token=" + localStorage.getItem('logintoken')).then(res => res.data) };
// // 交易订单-交易订单退款
// export const OrderRefund = (params) => { return req("post", "/api/Order/refund", params) };

/**
 * 渠道配置
 */
//新增渠道
export const addChannel = (params) => { return req("post", "/channel/create", params) };
export const editChannel = (params) => { return req("put", "/channel/edit", params) };
export const deleteChannel = (params) => { return req("delete", "/channel", params) };

export const getChannel = (params) => { return req("get", "/channel/get_by_condition?page=" + params.page, params) };
export const getChannelByCondition = (params) => {
    return req("get", "/channel/get_by_condition?page=" + params.page + "&channelName=" + params.channelName + "&startDate=" + params.startDate + "&endDate=" + params.endDate, params)
};

/**
 * 文章类目配置
 */
export const getCategoryByCondition = (params) => {
    return req("get", "/category/get_by_condition?page=" + params.page + "&categoryName=" + params.categoryName + "&startDate=" + params.startDate + "&endDate=" + params.endDate, params)
};
export const addCategory = (params) => { return req("post", "/category/create", params) };
export const deleteCategory = (params) => { return req("delete", "/category", params) };
export const editCategory = (params) => { return req("put", "/category/edit", params) };
export const configCategory = (params) => { return req("put", "/category/config", params) };
export const getCategoryList = (params) => { return req("get", "/category/get_category_list", params) };

/**
 * 轮播图配置
 */

export const addHomeImage = (params) => { return req("post", "/config/create_home_image", params) };
export const getHomeImages = (params) => { return req("get", "/config/get_home_images", params) };
export const editHomeImage = (params) => { return req("put", "/config/edit_home_image", params) };
export const deleteHomeImage = (params) => { return req("delete", "/config/delete_home_page_by_id", params) };

/**
 * 礼物
 */
export const addGift = (params) => { return req("post", "/gift/create", params) };
export const getGiftByCondition = (params) => {
    return req("get", "/gift/get_by_condition?page=" + params.page + "&title=" + params.title + "&startDate=" + params.startDate + "&endDate=" + params.endDate, params)
};
export const deleteGift = (params) => { return req("delete", "/gift", params) };
export const editGift = (params) => { return req("put", "/gift/edit", params) };
