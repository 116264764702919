<template>
  <div style="height: 100%;position: relative;">

    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right" style="width: 60%;margin-bottom: 30px;">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>数据总览</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 搜索筛选 -->
    <el-form :inline="true" :model="formInline" class="user-search" style="width: 100%;">

      <el-form-item>
        <el-radio-group v-model="tabPosition" style="margin-bottom: 2px;margin-right: 10px;" @input="tabClickHandle">
          <el-radio-button label="7">近7天</el-radio-button>
          <el-radio-button label="14">近14天</el-radio-button>
          <el-radio-button label="30">近30天</el-radio-button>
        </el-radio-group>
        <el-date-picker v-model="formInline.date" @change="dateChange" type="daterange" range-separator="至"
          start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd HH:mm:ss"
          :default-time="['00:00:00', '23:59:59']">
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button size="small" type="primary" icon="el-icon-search" @click="search">搜索</el-button>
        <el-button size="small" type="info" icon="el-icon-refresh-right" @click="resetFormInLine">重置</el-button>
        <!-- <el-button size="small" type="primary" icon="el-icon-plus" @click="handleEdit()">添加</el-button> -->
      </el-form-item>
    </el-form>


    <div style="width: 100%;margin-top: 30px;display: flex;justify-content: center;">
      <div style="display: flex;width: 98%;justify-content: center;flex-direction: column;">
        <div style="display: flex;width: 100%;justify-content: space-evenly;">
          <!-- <div @click="toDownloadRecordHandle" class="data-container">
            <div class="data">{{ dataOverview.downloadNumber }}</div>
            <div>下载量</div>
          </div> -->
          <div @click="toDownloadRecordHandle" class="data-container">
            <div class="data-container-left" style="background-color: #F03212;">
              <img class="data-container-images" src="../../assets/icon/data-icon.png">
              </img>
            </div>
            <div class="data-container-right">
              <div class="data-container-right-up" style="color: #F03212;">{{
                dataOverview.downloadNumber }}</div>
              <div class="data-container-right-down">下载量</div>
            </div>
          </div>
          <div style="display: flex;align-items: center;">
            <div style="border-left: 1px solid #909399;height: 50px;"></div>
          </div>
          <div @click="toSubmitRecordHandle" class="data-container">
            <div class="data-container-left" style="background-color: #7BDFF2;">
              <img class="data-container-images" src="../../assets/icon/data-icon.png">
              </img>
            </div>
            <div class="data-container-right">
              <div class="data-container-right-up" style="color: #7BDFF2;">{{
                dataOverview.formSubmitNumber }}</div>
              <div class="data-container-right-down">表单提交量</div>
            </div>
          </div>

          <!-- <div @click="toSubmitRecordHandle" class="data-container">
            <div class="data">{{ dataOverview.formSubmitNumber }}</div>
            <div>表单提交量</div>
          </div> -->

          <div style="display: flex;align-items: center;">
            <div style="border-left: 1px solid #909399;height: 50px;"></div>
          </div>
          <div @click="toReadingRecordHandle" class="data-container">
            <div class="data-container-left" style="background-color: #19535F;">
              <img class="data-container-images" src="../../assets/icon/data-icon.png">
              </img>
            </div>
            <div class="data-container-right">
              <div class="data-container-right-up" style="color: #19535F;">{{
                dataOverview.articleBrowsingNumber }}</div>
              <div class="data-container-right-down">文章阅读量</div>
            </div>
          </div>
          <!-- <div @click="toReadingRecordHandle" class="data-container">
            <div class="data">{{ dataOverview.articleBrowsingNumber }}</div>
            <div>文章阅读量</div>
          </div> -->

          <div style="display: flex;align-items: center;">
            <div style="border-left: 1px solid #909399;height: 50px;"></div>
          </div>
          <div @click="toVisitRecordHandle" class="data-container">
            <div class="data-container-left" style="background-color: #484E53;">
              <img class="data-container-images" src="../../assets/icon/data-icon.png">
              </img>
            </div>
            <div class="data-container-right">
              <div class="data-container-right-up" style="color: #484E53;">{{
                dataOverview.visitorNumber }}</div>
              <div class="data-container-right-down">访客数量</div>
            </div>
          </div>
          <!-- <div @click="toVisitRecordHandle" class="data-container">
            <div class="data">{{ dataOverview.visitorNumber }}</div>
            <div>访客数量</div>
          </div> -->
          <div style="display: flex;align-items: center;">
            <div style="border-left: 1px solid #909399;height: 50px;"></div>
          </div>
          <div class="data-container">
            <div class="data-container-left" style="background-color: #409EFF;">
              <img class="data-container-images" src="../../assets/icon/data-icon.png">
              </img>
            </div>
            <div class="data-container-right">
              <div class="data-container-right-up" style="color: #409EFF;">{{
                dataOverview.toHomeNumber }}</div>
              <div class="data-container-right-down">官网点击量</div>
            </div>
          </div>
          <!-- <div class="data-container2">
            <div class="data">{{ dataOverview.toHomeNumber }}</div>
            <div>官网点击量</div>
          </div> -->
        </div>

        <div style="display: flex;width: 100%;justify-content: space-evenly;margin-top: 50px;">
          <div class="data-container">
            <!-- <div class="data-container-left" style="background-color: #E6A23C;">
              <img class="data-container-images" src="../../assets/icon/data-icon.png">
              </img>
            </div> -->

            <div class="data-container-right" style="width: 150px;margin: 0;padding: 10px;">
              <div class="data-container-right-up" style="color: #E6A23C;font-size: 16px;">{{ dataOverview.channel + ' /
              '
                +
                dataOverview.channelNumber }}</div>
              <div class="data-container-right-down">渠道贡献量</div>
            </div>
          </div>
          <div style="display: flex;align-items: center;margin-right: 40px;">
            <div style="border-left: 1px solid #909399;height: 50px;"></div>
          </div>
          <!-- <div class="data-container2">
            <div class="data2">{{ dataOverview.channel + '/' + dataOverview.channelNumber }}</div>
            <div>渠道贡献量</div>
          </div> -->
          <div class="data-container">
            <!-- <div class="data-container-left" style="background-color: #67C23A;">
              <img class="data-container-images" src="../../assets/icon/data-icon.png">
              </img>
            </div> -->
            <div class="data-container-right" style="font-size: 12px;width: 150px;margin: 0;padding: 10px;">
              <div class="data-container-right-up"
                style="color: #67C23A;font-size: 16px; overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">{{
                  dataOverview.articleTitle + '/' +
                  dataOverview.articleNumber }}</div>
              <div class="data-container-right-down">内容贡献量</div>
            </div>
          </div>
          <!-- <div class="data-container2">
            <div class="data2">{{ dataOverview.articleTitle + '/' + dataOverview.articleNumber }}</div>
            <div>内容贡献量</div>
          </div> -->



        </div>
      </div>
    </div>
    <div style="width: 100%;height: auto; bottom: 0px;left: 0;margin: auto; position: absolute;">
      <img src="../../assets/img/Airframer_gif.gif" style="width: 100%;height: 100%;">
      </img>
    </div>
    <!-- <div style="width: 100%;height: 300px;bottom: 0;margin: auto;position: relative;" ref="echart">

    </div> -->
  </div>
</template>

<script>
import router from '@/router'
import { getDateOverview } from '../../api/recordMG'
import { timestampToDateRangeStart, timestampToDateRangeEnd } from '../../utils/util'
import * as echarts from 'echarts';
export default {
  data() {
    return {
      loading: false, //是显示加载
      formInline: {
        date: [],
        startDate: '',
        endDate: '',
      },
      dataOverview: { channel: '', channelNumber: 0, articleTitle: '', articleNumber: 0 }, //用户数据
      tabPosition: '7',
      clientWidth: 0,
      imageHeight: 0,
      imageWidth: 0,
      imageRight: 0,
      option: {
        xAxis: {
          type: 'category',
          data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: [120, 200, 150, 80, 70, 110, 130],
            type: 'bar'
          }
        ]
      }

    }
  },

  /**
   * 数据发生改变
   */

  /**
   * 创建完毕
   */
  created() {
  },
  mounted() {
    this.initFormInline()
    this.getdata()
    this.updateViewportDimensions();
    window.addEventListener('resize', this.updateViewportDimensions);
    this.initEchart()
  },
  /**
   * 里面的方法只有被调用才会执行
   */
  methods: {
    // 获取公司列表
    async getdata() {
      //this.loading = true
      let res = await getDateOverview(this.formInline)
      if (res.data && res.code === 200) {
        this.dataOverview = res.data
      }
    },
    //日期选择器中日期变化时触发
    dateChange() {
      if (this.formInline.date) {
        this.formInline.startDate = this.formInline.date[0]
        this.formInline.endDate = this.formInline.date[1]
      } else {
        this.formInline.startDate = ''
        this.formInline.endDate = ''
      }
    },

    // 搜索事件
    async search() {
      this.tabPosition = ''
      if (!this.formInline.date) {
        this.tabPosition = '7'
        this.initFormInline()
      }
      this.getdata()
    },
    updateViewportDimensions() {
      this.clientWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      //  this.viewportHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
      this.clientWidth = this.clientWidth - 240
      this.imageWidth = this.clientWidth * 0.6
      this.imageRight = (this.clientWidth - this.imageWidth) / 2
      this.imageHeight = this.imageWidth * 1650 / 6600
      console.log("视口宽度", this.clientWidth)
    },


    tabClickHandle() {
      this.formInline.date = []
      let dataArray = []
      if (this.tabPosition == '7') {
        dataArray = this.getDateArrayByDays(7);
      } else if (this.tabPosition == '14') {
        dataArray = this.getDateArrayByDays(14);
      } else if (this.tabPosition == '30') {
        dataArray = this.getDateArrayByDays(30);
      }
      if (dataArray) {
        this.formInline.startDate = dataArray[0]
        this.formInline.endDate = dataArray[1]
      }

      this.getdata()
    },
    resetFormInLine() {
      this.formInline = {
        date: [],
        startDate: '',
        endDate: '',
      }
      this.tabPosition = '7'
      this.initFormInline()
      this.getdata()
    },
    getDateArrayByDays(days = 7) {
      let endDate = new Date();
      let startDate = new Date(endDate.getTime() - days * 24 * 60 * 60 * 1000); //30天 * 24小时/天 * 60分钟/小时 * 60秒/分钟 * 1000毫秒/秒

      return [timestampToDateRangeStart(startDate), timestampToDateRangeEnd(endDate)];
    },
    initFormInline() {
      let dataArray = this.getDateArrayByDays(7);
      this.formInline.startDate = dataArray[0]
      this.formInline.endDate = dataArray[1]
    },
    toDownloadRecordHandle() {
      // router.push({ path: '/record/DownloadRecordList?startDate=' + this.formInline.startDate + "&endDate=" + this.formInline.endDate })
      router.push({
        path: '/record/DownloadRecordList',
        query: {
          startDate: this.formInline.startDate,
          endDate: this.formInline.endDate
        }
      })

    }
    ,
    toSubmitRecordHandle() {
      router.push({
        path: '/record/FormSubmitRecordList', query: {
          startDate: this.formInline.startDate,
          endDate: this.formInline.endDate
        }
      })
    }
    ,
    toReadingRecordHandle() {
      router.push({
        path: '/record/ArticleBrowsingRecordList', query: {
          startDate: this.formInline.startDate,
          endDate: this.formInline.endDate
        }
      })
    }
    ,
    toVisitRecordHandle() {
      router.push({
        path: '/statistics/VisitorStatisticsList', query: {
          startDate: this.formInline.startDate,
          endDate: this.formInline.endDate
        }
      })
    },
    initEchart() {
      console.log("初始化echart", this.$refs.echart)
      let myChart = echarts.init(this.$refs.echart)
      myChart.setOption(this.option)
    }
  }
}
</script>

<style scoped>
.user-search {
  margin-top: 20px;
}

.userRole {
  width: 100%;
}

.data-container {
  /* display: flex;
  flex-direction: column;
  width: 500px;
  align-items: center; */

  height: 90px;
  display: flex;
  align-items: center;
  /* box-shadow: 0 0 3px black; */
  /* border: 1px solid black; */
  /* background-color: #F0F0F0; */
  border-radius: 10px;
  padding-left: 10px;
}

.data-container-left {
  width: 60px;
  height: 60px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.data-container-right {
  width: 90px;
  display: flex;
  flex-direction: column;
  margin-left: 15px;
  height: 60px;
}

.data-container-right-up {
  line-height: 30px;
  height: 30px;
  font-size: 25px;
  font-weight: bold;
}

.data-container-right-down {
  line-height: 30px;
  height: 30px;
  font-size: 15px;
  color: #909399;
}

.data-container-images {
  width: 50px;
  height: 50px;

}

.data-container2 {
  display: flex;
  flex-direction: column;
  width: 500px;
  align-items: center;
}

.data-container :hover {
  cursor: pointer;
}

.data {
  font-size: 70px;
  color: #409EFF;
}

.data2 {
  font-size: 50px;
  color: #409EFF;
}
</style>